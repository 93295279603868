import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail, reset } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import {
  Avatar,
  CssBaseline,
  Link,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href={process.env.BASE_URL}>
        Bowtie.app
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { isVerified, isLoading, isError, message, isSuccess} = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (token) {
      dispatch(verifyEmail(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (isSuccess && isVerified) {
      toast.success("Email verified successfully");
      setTimeout(() => {
        dispatch(reset());
        navigate('/login'); // Redirect to login after verification
      }, 3000); // Delay reset to allow UI to update
    }

    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
  }, [isVerified, isSuccess, isError, message, navigate, dispatch]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Box
        sx={{
          marginTop: { xs: 2, sm: 8 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockResetIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Email Verification
        </Typography>
        <Typography component="p" variant="body">
          {isLoading && "Verifying..."}
          {isError && (
            <>
              Please <Link to="/login">try again</Link>
            </>
          )}
          {isVerified && (
            <>
              Your email account has been validated. Please{" "}
              <Link to="/login">login</Link>.
            </>
          )}
        </Typography>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

export default VerifyEmail;
