import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscribeForm from "../components/SubscribeForm";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function Subscribe() {
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState("")
  
  const location = useLocation();

  const STRIPE_PUBLISHABLE_KEY="pk_live_9HS7tsS4NDZ3ZlXQnKzcX1FD00OmHrh73q"
//const STRIPE_PUBLISHABLE_KEY = "pk_test_yvfcckKmeySobTz7x6hb06vR00o42TupVn";

  useEffect(()=> {
    setClientSecret(location.state.clientSecret)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    setStripePromise(loadStripe(STRIPE_PUBLISHABLE_KEY))
  }, [])

  const options = {
    clientSecret: clientSecret
  }

  return (
    <>
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <SubscribeForm  />
        </Elements>
      )}
    </>
  );
}

export default Subscribe;
