import React, { useMemo } from 'react';
import Xarrows from "react-xarrows";

function Lines(props) {
  const total = props.total;
  const arrowProps = {
    color: "#495464",
    headSize: 0,
    path: "smooth",
    monitorDOMchanges: "true",
    registerEvents: [],
  };

  const severityFinder = useMemo(() => {
    return (block) => {
      const result = total.severities.filter((severity) => {
        return severity._id === block.severity;
      });
      if (result.length === 0) {
        return 5;
      } else {
        return result[0].number;
      }
    };
  }, [total.severities]);

  const likelihoodFinder = useMemo(() => {
    return (block) => {
      const result = total.likelihoods.filter((likelihood) => {
        return likelihood._id === block.likelihood;
      });
      if (result.length === 0) {
        return 5;
      } else {
        return result[0].number;
      }
    };
  }, [total.likelihoods]);

  const preventEffectivenessMultiplier = (block, arr, i) => {
    let effectiveness = 1;
    for (let j = 0; j === i; j++) {
      effectiveness += ((arr[j].quality / 100) * arr[j].condition) / 100;
    }
    const lineThickness = likelihoodFinder(block) * effectiveness;
    return lineThickness;
  };

  const mitigateEffectivenessMultiplier = (block, arr, i) => {
    let effectiveness = 1;
    for (let j = 0; j === i; j++) {
      if (arr.length !== 0) {
        effectiveness += ((arr[j].quality / 100) * arr[j].condition) / 100;
      }
    }
    const lineThickness = severityFinder(block) * effectiveness;
    return lineThickness;
  };

  function BarrierArrows(props) {
    const { blocks, blockType } = props;

    return blocks.map((block) => {
      const barrierChildren = total.barriers.filter((barrier) => {
        return barrier[blockType] === block._id;
      });

      if (barrierChildren.length === 0) {
        return (
          <Xarrows
            start={block._id}
            end={total.bowtie._id}
            key={block._id + "_" + total.bowtie._id}
            strokeWidth={
              blockType === "threat"
                ? likelihoodFinder(block) * 2
                : severityFinder(block) * 2
            }
            {...arrowProps}
          />
        );
      } else {
        return barrierChildren.map((barrier, i, arr) => {
          return (
            <>
              {i === 0 ? ( //are you the first block next to the threat or consequence
                <Xarrows
                  start={block._id}
                  end={barrier._id}
                  key={block._id + "_" + barrier._id + "_" + i}
                  strokeWidth={
                    blockType === "threat"
                      ? likelihoodFinder(block) * 2
                      : severityFinder(block) * 2
                  }
                  {...arrowProps}
                />
              ) : (
                //or are the the remaining option, the arrow between two barriers THIS IS NEVER REACHED THAT'S WHY THIS WAS NOT NESTED!! LOL
                <Xarrows
                  start={arr[i - 1]._id}
                  end={arr[i]._id}
                  key={barrier._id}
                  strokeWidth={
                    blockType === "threat"
                      ? preventEffectivenessMultiplier(block, arr, i) * 2
                      : mitigateEffectivenessMultiplier(block, arr, i) * 2
                  }
                  {...arrowProps}
                />
              )}
              {arr.length - 1 === i ? ( //are you the final block on the row
                <Xarrows
                  start={barrier._id}
                  end={total.bowtie._id}
                  key={barrier._id + "_" + total.bowtie._id + "_" + i}
                  strokeWidth={
                    blockType === "threat"
                      ? preventEffectivenessMultiplier(block, arr, i) * 2
                      : mitigateEffectivenessMultiplier(block, arr, i) * 2
                  }
                  startAnchor={blockType === "threat" ? "right" : "left"}
                  endAnchor={blockType === "threat" ? "left" : "right"}
                  {...arrowProps}
                />
              ) : (
                ""
              )}
            </>
          );
        });
      }
    });
  }

  return (
    <>
      <BarrierArrows blocks={total.threats} blockType={"threat"} />
      <BarrierArrows blocks={total.consequences} blockType={"consequence"} />
    </>
  );
}

export default Lines;
