import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import authService from './authService'

//Get user from local storage
const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    isEmailSentSuccess: false,
    isVerified: false
}

//Register User
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
    try {
        return await authService.register(user)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//Resend Verification Email
export const resendVerificationEmail = createAsyncThunk('auth/resendVerificationEmail', async (email, thunkAPI) => {
    try {
        //console.log("FROM SLICE: ",email)
        return await authService.resendVerificationEmail(email)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Email Verification
export const verifyEmail = createAsyncThunk('auth/verifyEmail', async (token, thunkAPI) => {
    try {
        const response = await authService.verifyEmail(token);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

//Log In
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
    try {
        return await authService.login(user)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const logout = createAsyncThunk('auth/logout', async () => {
    await authService.logout()
})

//newsletter signup
export const newsletterSignup = createAsyncThunk('auth/newsletterSignup', async (email, thunkAPI) => {
    try {
        return await authService.newsletterSignup(email)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//Auth Slice
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
            state.isEmailSentSuccess = false
            state.isVerified = false
        },
        clearUser: (state) => {
            state.user = null;
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isVerified = false;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(register.rejected, (state, action) =>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(resendVerificationEmail.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(resendVerificationEmail.fulfilled, (state) => {
                state.isLoading = false;
                state.isEmailSentSuccess = true; // Set email sent success state
                state.message = 'Verification email resent successfully';
            })
            .addCase(resendVerificationEmail.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(verifyEmail.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(verifyEmail.fulfilled, (state) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isVerified = true; // Set the verified state
                if (state.user) {
                    state.user.verified = true; // Update the verified status in the user object
                }
            })
            .addCase(verifyEmail.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
                state.isVerified = action.payload.verified
            })
            .addCase(login.rejected, (state, action) =>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = false;
                state.isVerified = false;
                state.message = '';
            });

    }
})

export const {reset, clearUser} = authSlice.actions
export default authSlice.reducer