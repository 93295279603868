import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {  CircularProgress,} from "@mui/material";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

function ThreatsProfile() {

  const { user } = useSelector((state) => state.auth);
  const [dashboard, setDashboard] = useState();
  const navigate = useNavigate();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Preventative Barrier Status",
      },
    },
  };

  useEffect(() => {
    if (!user) {
      navigate("/login"); // Redirect to login if no user
    } else {
      fetchThreatData();
    }
    // eslint-disable-next-line
  }, [user]);

  const fetchThreatData = async () => {
    const { threatsProfile } = await fetch("/api/dashboard", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    }).then((r) => r.json());
    
    setDashboard({ threatsProfile });
    
  };

  if (!user) {
    return null; // Render nothing if no user
  }

  if (!dashboard) {
    return <CircularProgress />; // Render loading state if dashboard data is not yet available
  }

  if (dashboard.threatsProfile !== undefined) {
    
    const labels = dashboard.threatsProfile.map((threat) => threat.threatTitle);

    const data = {
      labels,
      datasets: [
        {
          label: "Likelihood",
          data: dashboard.threatsProfile.map((threat) => threat.likelihood),
          backgroundColor: "#6495ED",
          // backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Prevented Likelihood",
          data: dashboard.threatsProfile.map((threat) => threat.liveLikelihood),
          backgroundColor: "#B0CDFF",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
    return <Bar options={options} data={data} />;
  }
}

export default ThreatsProfile;
