import {useState} from 'react'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { createBowtie } from "../features/bowties/bowtieSlice";
import BowtieIconOrange from '../components/icons/BowtieIconOrange'
import { useNavigate } from 'react-router-dom';
import {getThreats} from '../features/bowties/threatSlice'
import {getBarriers} from '../features/bowties/barrierSlice'
import {getConsequences} from '../features/bowties/consequenceSlice'

function NewBowtieModal(props) {

  const [newBowtie, setNewBowtie] = useState({title: "", description: "", event:""})
  const [titleError, setTitleError] = useState(false)
  const [eventError, setEventError] = useState(false)

  const handleClose = () => {
    props.setOpenNewBowtieModal(false);
  };

  const handleChangeTitle = (e) => {    
    setTitleError(false)
    setNewBowtie({
      ...newBowtie,
      title: e.target.value,
    });
  };

  const handleChangeDescription = (e) => {    
    setNewBowtie({
      ...newBowtie,
      description: e.target.value,
    });
  };

  const handleChangeEvent = (e) => {    
    setEventError(false)
    setNewBowtie({
      ...newBowtie,
      event: e.target.value,
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  async function handleSave() {
    //clean up
    setTitleError(false)
    setEventError(false)

    if(newBowtie.title === "") {
      setTitleError(true)
    } else if (newBowtie.event === ""){
      setEventError(true)
    } else {
      const res = await dispatch(createBowtie(newBowtie))
      navigate("/editor/"+res.payload._id)
      dispatch(getThreats(res.payload._id))
      dispatch(getBarriers(res.payload._id))
      dispatch(getConsequences(res.payload._id))
      handleClose()
    }
  }
    

  return (
    <div>
      <Dialog open={props.openNewBowtieModal} onClose={handleClose}>
        <DialogTitle>
          <BowtieIconOrange sx={{ color: "#6495ED", position:'absolute', top:20, right:20 }} />
          Create a new Bowtie
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            fullWidth
            required
            error={titleError}
            helperText={titleError?"required":""}
            name="title"
            variant="outlined"
            onChange={handleChangeTitle}
            placeholder={"Please provide a title for this bowtie"}
          />
          <TextField
          margin="dense"
          label="Description"
          fullWidth
          multiline
          rows={4}
          name="description"
          variant="outlined"
          onChange={handleChangeDescription}
          placeholder={"Please provide a description of this bowtie"}
          />
          <TextField
          margin="dense"
          label="Event"
          fullWidth
          required
          error={eventError}
          name="event"
          variant="outlined"
          onChange={handleChangeEvent}
          placeholder={"Please provide a description of the hazardous event"}
          helperText={eventError?"required":""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary" variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NewBowtieModal;
