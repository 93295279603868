//THIS PAGE CONTAINS A TABLE OF BARRIERS BELONGING TO ONE BOWTIE
//IT'S THE BOWTIE's TABLE

import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import BoltIcon from "@mui/icons-material/Bolt";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import EditThreatBarrierModal from "./EditThreatBarrierModal";
import EditConsequenceBarrierModal from "./EditConsequenceBarrierModal";
import { IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getBowties } from "../features/bowties/bowtieSlice";
import { getSeverities } from "../features/bowties/severitySlice";
import { getThreats } from "../features/bowties/threatSlice";
import { getBarriers } from "../features/bowties/barrierSlice";
import { getConsequences } from "../features/bowties/consequenceSlice";
import { reset } from "../features/auth/authSlice";

const columns = [
  { id: "threat-consequence-icon", label: "", minWidth: 25 },
  { id: "threat-consequence", label: "Threat/Consequence", minWidth: 180 },
  { id: "barrier-title", label: "Barrier Title", minWidth: 180 },
  { id: "barrier-description", label: "Barrier Description", minWidth: 180 },

  {
    id: "quality",
    label: "Quality %",
    minWidth: 100,
    align: "right",
  },
  {
    id: "condition",
    label: "Condition %",
    minWidth: 100,
    align: "right",
  },
  {
    id: "effectiveness",
    label: "Effectiveness %",
    minWidth: 100,
    align: "right",
  },
  {
    id: "edit",
    label: "",
    minWidth: 100,
    align: "right",
  },
];

export default function StickyHeadTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bowtieParam = useParams();
  const { user } = useSelector((state) => state.auth);
  const { bowties } = useSelector((state) => state.bowties);
  const { threats } = useSelector((state) => state.threats);
  const { barriers } = useSelector((state) => state.barriers);
  const { consequences } = useSelector((state) => state.consequences);

  if (
    threats.length === 0 ||
    consequences.length === 0 ||
    barriers.length === 0
  ) {
    dispatch(getBowties());
    dispatch(getSeverities());
    dispatch(getThreats(bowtieParam.bowtie));
    dispatch(getBarriers(bowtieParam.bowtie));
    dispatch(getConsequences(bowtieParam.bowtie));
  }

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    if (!bowtieParam) {
      console.log("we didn't find a bowtie param so navigating home");
      navigate("/");
    }

    return () => {
      dispatch(reset());
    };
  }, [user, bowtieParam, bowties, dispatch, navigate]);

  const [openThreatBarrierModal, setOpenThreatBarrierModal] = useState(false);
  const [threatBarrierId, setThreatBarrierId] = useState();

  const [openConsequenceBarrierModal, setOpenConsequenceBarrierModal] =
    useState(false);
  const [consequenceBarrierId, setConsequenceBarrierId] = useState();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //open the edit threat barrier modal
  const editThreatBarrier = (threatBarrierId) => {
    setThreatBarrierId(threatBarrierId);
    setOpenThreatBarrierModal(true);
  };

  //open the edit threat barrier modal
  const editConsequenceBarrier = (consequenceBarrierId) => {
    setConsequenceBarrierId(consequenceBarrierId);
    setOpenConsequenceBarrierModal(true);
  };

  const threatBarrierModal = () => {
    if (threatBarrierId) {
      return (
        <EditThreatBarrierModal
          openThreatBarrierModal={openThreatBarrierModal}
          setOpenThreatBarrierModal={setOpenThreatBarrierModal}
          threatBarrierId={threatBarrierId}
          setThreatBarrierId={setThreatBarrierId}
        />
      );
    }
  };

  const consequenceBarrierModal = () => {
    if (consequenceBarrierId) {
      return (
        <EditConsequenceBarrierModal
          openConsequenceBarrierModal={openConsequenceBarrierModal}
          setOpenConsequenceBarrierModal={setOpenConsequenceBarrierModal}
          consequenceBarrierId={consequenceBarrierId}
          setConsequenceBarrierId={setConsequenceBarrierId}
        />
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const threatOrConsequence = (id) => {
    const threat = threats.filter((threat) => threat._id === id);
    const consequence = consequences.filter(
      (consequence) => consequence._id === id
    );

    if (threat.length > 0) {
      return threat[0].title;
    } else if (consequence.length > 0) {
      return consequence[0].title;
    }
  };

  const threatOrConsequenceIcon = (id) => {
    const threat = threats.filter((threat) => threat._id === id);
    const consequence = consequences.filter(
      (consequence) => consequence._id === id
    );

    if (threat.length > 0) {
      return <BoltIcon sx={{ color: "#6495ED" }} />;
    } else if (consequence.length > 0) {
      return <MinorCrashIcon sx={{ color: "#FF4E33" }} />;
    }
  };

  const threatOrConsequenceEdit = (parentId, barrierId) => {
    const threat = threats.filter((threat) => threat._id === parentId);
    const consequence = consequences.filter(
      (consequence) => consequence._id === parentId
    );

    if (threat.length > 0) {
      return (
        <IconButton
          onClick={function () {
            editThreatBarrier(barrierId);
          }}
        >
          <EditIcon />
        </IconButton>
      );
    } else if (consequence.length > 0) {
      return (
        <IconButton
          onClick={function () {
            editConsequenceBarrier(barrierId);
          }}
        >
          <EditIcon />
        </IconButton>
      );
    }
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {barriers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((barrier) => {
                  return (
                    <TableRow key={barrier._id}>
                      <TableCell
                        key={"parent-icon" + barrier._id}
                        align={"left"}
                      >
                        {barrier.threat
                          ? threatOrConsequenceIcon(barrier.threat)
                          : threatOrConsequenceIcon(barrier.consequence)}
                      </TableCell>
                      <TableCell key={"parent" + barrier._id} align={"left"}>
                        {barrier.threat
                          ? threatOrConsequence(barrier.threat)
                          : threatOrConsequence(barrier.consequence)}
                      </TableCell>
                      <TableCell key={"title" + barrier._id} align={"left"}>
                        {barrier.title}
                      </TableCell>
                      <TableCell key={"descr" + barrier._id} align={"left"}>
                        {barrier.description}
                      </TableCell>

                      <TableCell key={"quality" + barrier._id} align={"right"}>
                        {barrier.quality}
                      </TableCell>
                      <TableCell
                        key={"condition" + barrier._id}
                        align={"right"}
                      >
                        {barrier.condition}
                      </TableCell>
                      <TableCell
                        key={"effectiveness" + barrier._id}
                        align={"right"}
                      >
                        <Chip
                          key={"chip" + barrier._id}
                          color="primary"
                          label={Math.round((barrier.condition * barrier.quality) / 100)}
                          style={{color: 'black',
                            backgroundColor: `rgb(255, ${
                              255 * (barrier.condition/100 * barrier.quality/100)
                            },${
                                255 * (barrier.condition/100 * barrier.quality/100)
                              })`,
                          }}
                        />
                      </TableCell>
                      <TableCell key={"edit" + barrier._id} align={"right"}>
                        {barrier.threat
                          ? threatOrConsequenceEdit(barrier.threat, barrier._id)
                          : threatOrConsequenceEdit(
                              barrier.consequence,
                              barrier._id
                            )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={barriers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {threatBarrierModal()}
      {consequenceBarrierModal()}
    </>
  );
}
