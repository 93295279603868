import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import {login, reset, resendVerificationEmail} from '../features/auth/authSlice'
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, CircularProgress } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={process.env.BASE_URL}>
        Bowtie.app
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const [showVerificationMessage, setShowVerificationMessage] = useState(false);
    
    const { email, password } = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {user, isLoading, isSuccess, isError, message, isEmailSentSuccess } = useSelector((state) => state.auth)
    
    useEffect(() => {
        if(isError){
            toast.error(message)
        }

        if (isSuccess && user) {
          if (user.verified) {
              navigate('/'); // Redirect to home page if verified
          } else {
              setShowVerificationMessage(true); // Show verification message if not verified
              dispatch(reset());
          }
      }

        dispatch(reset())
    }, [user, isError, isSuccess, message, navigate, dispatch])

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const userData = {
            email,
            password
        }

        dispatch(login(userData))

    }

    const handleResendVerificationEmail = () => {
      
      if (email) {
          dispatch(resendVerificationEmail(email));
      }
  };

  useEffect(() => {
    if (isEmailSentSuccess) {
        toast.success('Verification email resent successfully');
        // navigate('/');
        dispatch(reset());
    }
}, [isEmailSentSuccess, message, navigate, dispatch]);

    if(isLoading){
        return <CircularProgress />
    }

    return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          {showVerificationMessage ? (
            
              <div>
                  <Typography component="h1" variant="h5">It looks like you have a user account but you haven't verified your email address yet.</Typography>
                  <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleResendVerificationEmail}
            >
              Resend Verification Email
            </Button>
              </div>
          ):
          (<>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onChange}
            />
    
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onChange}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          </>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />  
    </Container>
        
    )
}

export default Login