import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createConsequence } from "../features/bowties/consequenceSlice";
import { useParams } from "react-router-dom";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import {
  Tooltip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import axios from "axios";

function NewConsequenceModal(props) {
  const { bowtie } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [aiButton, setAiButton] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const severities = useSelector((state) => state.severity);

  const initial = {
    title: "",
    description: "",
    bowtie: bowtie,
    severity: "",
    severityValue: ""
  };

  const [newConsequence, setNewConsequence] = useState(initial);

  useEffect(() => {
    newConsequence.description === "" ? setAiButton(true) : setAiButton(false);
  }, [newConsequence.description]);

  const handleClose = () => {
    props.setOpenNewConsequenceModal(false);
    setAiButton(true);
    setNewConsequence(initial);
  };

  const handleChangeTitle = (e) => {
    setTitleError(false);
    setNewConsequence({
      ...newConsequence,
      title: e.target.value,
    });
  };

  const handleChangeDescription = (e) => {
    setNewConsequence({
      ...newConsequence,
      description: e.target.value,
    });
  };

  const handleChangeSeverity = (e) => {
    const selectedSeverity = severities.severities.find(severity => severity._id === e.target.value);
    const severityValue = selectedSeverity ? selectedSeverity.number : 1;
    setNewConsequence({
      ...newConsequence,
      severity: e.target.value,
      severityValue: severityValue,
    });
  };

  const severitySelect = severities.severities.map((severity) => (
    <MenuItem value={severity._id} key={severity._id}>
      {severity.number}: {severity.severity}
    </MenuItem>
  ));

  const generateConsequenceTitle = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setAiButton(true);
    const response = await axios.post(
      "/api/ai/consequence",
      { description: newConsequence.description },
      config
    );
    setAiButton(false);
    //fake an onChange event from the DOM to piggy back the handler, so as to avoid invoking a controlled input by setting its value
    const newObj = { target: { value: response.data } };
    handleChangeTitle(newObj);
  };

  const dispatch = useDispatch();

  const handleSave = () => {
    //clean up
    setTitleError(false);

    if (newConsequence.title === "") {
      setTitleError(true);
    } else {
      dispatch(createConsequence(newConsequence));
      handleClose();
    }
  };

  return (
    <Dialog open={props.openNewConsequenceModal} onClose={handleClose}>
      <DialogTitle>
        <MinorCrashIcon
          sx={{ color: "#FF4E33", position: "absolute", top: 20, right: 20 }}
        />
        Create a new Consequence
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={10}>
            <TextField
              autoFocus
              margin="dense"
              label="Title"
              fullWidth
              required
              error={titleError}
              helperText={titleError ? "required" : ""}
              name="title"
              variant="outlined"
              onChange={handleChangeTitle}
              placeholder={"Please provide a title for this consequence"}
              value={newConsequence.title}
            />
          </Grid>
          <Grid
            item
            xs={2}
            p={1}
            alignItems="stretch"
            style={{ display: "flex" }}
          >
            <Tooltip
              title={
                !aiButton
                  ? "use AI 🤖 to convert your description into a concise summarised title"
                  : ""
              }
            >
              <Button
                variant="contained"
                name="generate ai summary title"
                color="secondary"
                onClick={generateConsequenceTitle}
                disabled={aiButton}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  minWidth: "100%",
                  minHeight: "100%",
                }}
              >
                <PsychologyOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              multiline
              rows={4}
              name="description"
              variant="outlined"
              onChange={handleChangeDescription}
              placeholder={"Please provide a description of this consequence"}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <FormControl fullWidth>
              <InputLabel id="severity-label">Severity</InputLabel>
              <Select
                labelId="severity-select-label"
                id="severity-select"
                value={newConsequence.severity}
                label="Severity"
                onChange={handleChangeSeverity}
              >
                {severitySelect}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewConsequenceModal;
