import React, {useEffect, useRef} from "react";
import Xarrows from "react-xarrows";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateBarrier } from "../features/bowties/barrierSlice";
import { updateBowtie } from '../features/bowties/bowtieSlice';

function Lines() {
  const { bowtie } = useParams();
  const dispatch = useDispatch();

  const { bowties } = useSelector((state) => state.bowties);
  const { threats } = useSelector((state) => state.threats);
  const { barriers } = useSelector((state) => state.barriers);
  const { consequences } = useSelector((state) => state.consequences);

  //initialise temporary store of barrier weights
  const AllBarrierWeights = useRef([]);
  const accumulatedThreatSum = useRef(0);

  //find the bowtie with the id from the url
  const bowtieWorking = bowties.find((item) => {
    return item._id === bowtie;
  });

  const arrowProps = {
    color: "#495464",
    headSize: 0,
    path: "smooth",
    monitorDOMchanges: "true",
    registerEvents: [],
    zIndex: -1000,
  };

  const strokeWidth = 1;

  function BarrierArrows(data) {
    const { blocks, blockType } = data;
    
    if(blockType==="threat") {accumulatedThreatSum.current = 0;}
    
    //here block means a threat or a consequence, could be either, we call this function twice from the end of the page
    const connections = blocks.map((block, index) => {

      //use a clean array to store the weights of the barriers for this block
      const barrierWeightsTemporaryArray = [];
      //get all the barriers that belong to the threat or consequence (i.e. the block) being mapped
      const barrierChildren = barriers.filter((barrier) => {
        return barrier[blockType] === block._id;
      });

      //if there are no barriers for this block, draw a line between the threat/consequence and the bowtie
      if (barrierChildren.length === 0) {
        if (blockType === "threat") {
          accumulatedThreatSum.current += block.likelihoodValue ? block.likelihoodValue : 0;
          let thisWidth=block.likelihoodValue ? block.likelihoodValue : strokeWidth
          return (
            <Xarrows
              start={block._id}
              end={bowtieWorking._id}
              startAnchor={"right"}
              endAnchor={"left"}
              key={"arrow_"+block._id + "_" + bowtieWorking._id + "_" + index + "_t"}
              strokeWidth={
                thisWidth < 1 ? strokeWidth : thisWidth
              }
              {...arrowProps}
            />
          );
        } else if (blockType === "consequence") {
          return (
            <Xarrows
              start={bowtieWorking? bowtieWorking._id : null}
              end={block._id}
              startAnchor={"left"}
              endAnchor={"right"}
              key={"arrow_"+block._id + "_" + bowtieWorking._id + "_" + index + "_c"}
              strokeWidth={
                bowtieWorking ? bowtieWorking.threatSum : strokeWidth
              }
              {...arrowProps}
            />
          );
        } else {
          return null;
        }

      //next we process barrier lines when there is one barrier between the threat/consequence (the block) and the bowtie
      } else if (barrierChildren.length === 1) {
        //first handle missing variables:
        const c = barrierChildren[0].condition
          ? 1 - barrierChildren[0].condition / 100
          : 1;
        const q = barrierChildren[0].quality
          ? 1 - barrierChildren[0].quality / 100
          : 1;

        //there is only one barrier so we need to draw two lines
        //THREAT: one between the threat and the barrier and one between the barrier and the bowtie
        if (blockType === "threat") {
          //write the weight of the line between the barrier and the bowtie to the local array for sending to store after this entire map
          barrierWeightsTemporaryArray.push({_id: barrierChildren[0]._id, weight: block.likelihoodValue * c * q});
          //add the threat likelihood to the bowtie threatSum
          accumulatedThreatSum.current += block.likelihoodValue * c * q;
          let thisWidth = block.likelihoodValue ? block.likelihoodValue : strokeWidth
          let thisWidth2 = block.likelihoodValue ? Math.max(block.likelihoodValue * c * q, 0.1) : strokeWidth
          return (
            <>
              <Xarrows
                start={block._id}
                end={barrierChildren[0]._id}
                key={"arrow_"+block._id + "_" + barrierChildren[0]._id}
                startAnchor={"right"}
                endAnchor={"left"}
                strokeWidth={ thisWidth < 1 ? strokeWidth : thisWidth }
                {...arrowProps}
              />
              <Xarrows
                start={barrierChildren[0]._id}
                end={bowtieWorking._id}
                key={"arrow_"+barrierChildren[0]._id + "_" + bowtieWorking._id}
                startAnchor={"right"}
                endAnchor={"left"}
                strokeWidth={thisWidth2 < 1 ? strokeWidth : thisWidth2}
                {...arrowProps}
              />
            </>
          );
        } else if (blockType === "consequence") {
          //we are still working in the barriers count of 1 ifs here
          //CONSEQUENCE: one between the bowtie and the barrier and one between the barrier and the consequence
          //TO DO come and replace stroke width once you have summed all the threat lines at the bowtie!
          //TO DO, in here you need to dispatch an update to barrier weight for this solo barrier.
          barrierWeightsTemporaryArray.push({_id: barrierChildren[0]._id, weight: block.likelihoodValue * c * q});
          let bowtieToBarrier = bowtieWorking.threatSum < 1 ? strokeWidth : bowtieWorking.threatSum
          let barrierToConsequence = bowtieWorking.threatSum ? Math.max(bowtieWorking.threatSum * c * q, 0.1) : strokeWidth
          return (
            <>
              <Xarrows
                start={bowtieWorking._id}
                end={barrierChildren[0]._id}
                key={"arrow_"+barrierChildren[0]._id + "_" + bowtieWorking._id}
                startAnchor={"right"}
                endAnchor={"left"}
                strokeWidth={bowtieToBarrier}
                {...arrowProps}
              />
              <Xarrows
                start={barrierChildren[0]._id}
                end={block._id}
                key={"arrow_"+block._id + "_" + barrierChildren[0]._id}
                startAnchor={"right"}
                endAnchor={"left"}
                strokeWidth={barrierToConsequence}
                {...arrowProps}
              />
            </>
          );
        } else {
          return null;
        }
      } else if (barrierChildren.length > 1) {
        //there are > 1 barriers so lets draw all of the lines
        //first sort the array based on the barrier.position
        const sortedBarrierChildren = barrierChildren.sort((a, b) => a.position - b.position);

        //then map through this new position sorted array to draw the barriers:
        return sortedBarrierChildren.map((barrier, i, arr) => {
          //ON THE THREAT SIDE
          if (blockType === "threat") {
            const c = barrier.condition ? 1 - barrier.condition / 100 : 1;
            const q = barrier.quality ? 1 - barrier.quality / 100 : 1;
            const weight0 = block.likelihoodValue
              ? block.likelihoodValue * c * q
              : strokeWidth;
            
            //deal with the first barrier on this line:
              //starts on the threat, has weight equal to threat likelihood, ends on first barrier
              //THIS first barrier has a line equal to the threat likelihood on its left, but its weight we write to the store is the threat likelihood * c * q
            if (i === 0) {
              barrierWeightsTemporaryArray.push({ _id: barrier._id, weight: weight0 });
              
              return (
                <Xarrows
                  start={block._id}
                  end={barrier._id}
                  key={"arrow_"+block._id + "_" + barrier._id + "_" + i}
                  startAnchor={"right"}
                  endAnchor={"left"}
                  strokeWidth={
                    block.likelihoodValue ? block.likelihoodValue : strokeWidth
                  }
                  {...arrowProps}
                />
              );
            } else if (i !== arr.length - 1 && i !== 0) {
              //middle barriers on the threat side i.e. there are >1 barriers and this one is not first or last
              //NOTE Here we are calcuating the weight of THIS barrier (i) based on the previous barrier's weight and THIS barriers q and c
                //we also write the weight of this barrier to storage for the NEXT barrier to use. NOTE THAT WE ARE READING AND WRITING TO THE useRef AS WE CAN'T PERFORMANTLY WRITE THESE UPDATES TO STATE DURING RENDER.
                //we draw the lines on the left of this barrier using the previous barrier's weight as the strokeWidth. We must get this from the useRef.
                
                const iMinusOne = barrierWeightsTemporaryArray.find((item) => item._id === arr[i - 1]._id);
                const weightI = iMinusOne.weight !== undefined && iMinusOne.weight !== null 
                ? iMinusOne.weight * c * q
                : strokeWidth;
              
              //we add this barrier's weight to the local array for sending to the store after this entire map and for referring to when progressing through the barriers
              barrierWeightsTemporaryArray.push({ _id: barrier._id, weight: weightI });
              
              return (
                <Xarrows
                  start={arr[i - 1]._id}
                  end={arr[i]._id}
                  key={"arrow_"+barrier._id + "_" + i}
                  startAnchor={"right"}
                  endAnchor={"left"}
                  strokeWidth={weightI >= 1 ? weightI : strokeWidth}
                  {...arrowProps}
                />
              );
            } else if (i === arr.length - 1) {
              //Last barrier on the threat side
                //we have to draw the line between this barrier and its predecessor
                //AND the line between this barrier and the bowtie
              //have to put weightI here again because of scoping
              const iMinusOnePenultimate = barrierWeightsTemporaryArray.find((item) => item._id === arr[i - 1]._id);
              const weightLast = iMinusOnePenultimate.weight !== undefined && iMinusOnePenultimate.weight !== null ? iMinusOnePenultimate.weight * c * q : strokeWidth;
              barrierWeightsTemporaryArray.push({ _id: barrier._id, weight: weightLast });
              //and we add weight last to the accumulatedThreatSum
              accumulatedThreatSum.current += weightLast;
              
              return (
                <>
                  <Xarrows
                    start={arr[i - 1]._id}
                    end={arr[i]._id}
                    key={"arrow_"+barrier._id + "_" + i}
                    startAnchor={"right"}
                    endAnchor={"left"}
                    strokeWidth={ 
                      iMinusOnePenultimate.weight !== undefined && iMinusOnePenultimate.weight !== null && iMinusOnePenultimate.weight >=1 ? iMinusOnePenultimate.weight : strokeWidth
                    }
                    {...arrowProps}
                  />
                  <Xarrows
                    start={barrier._id}
                    end={bowtieWorking._id}
                    key={"arrow_"+barrier._id + "_" + bowtieWorking._id + "_" + i}
                    startAnchor={"right"}
                    endAnchor={"left"}
                    strokeWidth={weightLast <1 ? strokeWidth : weightLast}
                    {...arrowProps}
                  />
                </>
              );
            }
          } else if (blockType === "consequence") {
            if (i === arr.length - 1) {
              //last barrier on the consequence side (these are drawn backwards, so this is the first barrier!)
              //we know there is more than one barrier to end up here, so we need to draw the line
              //between the bowtie and the barrier and the barrier and its predecessor
              return (
                <>
                  <Xarrows
                    start={arr[i - 1]._id}
                    end={arr[i]._id}
                    key={"arrow_"+barrier._id + "_" + i}
                    {...arrowProps}
                  />
                  <Xarrows
                    start={bowtieWorking._id}
                    end={barrier._id}
                    key={"arrow_"+barrier._id + "_" + bowtieWorking._id + "_" + i}
                    startAnchor={"right"}
                    endAnchor={"left"}
                    {...arrowProps}
                  />
                </>
              );
            } else if (i === 0) {
              //first barrier on the consequence side
              return (
                <Xarrows
                  start={barrier._id}
                  end={block._id}
                  key={"arrow_"+barrier._id + "_" + block._id + "_" + i}
                  startAnchor={"right"}
                  endAnchor={"left"}
                  {...arrowProps}
                />
              );
            } else {
              //middle barriers on the consequence side
              return (
                <Xarrows
                  start={arr[i]._id}
                  end={arr[i + 1]._id}
                  key={"arrow_"+barrier._id + "_" + i}
                  {...arrowProps}
                />
              );
            }
          } else {
            return null;
          } return null;
        });
      }
      //write this barrier map loop's barrier weights to the out array
      AllBarrierWeights.current.push(...barrierWeightsTemporaryArray.current);
      //clear this barrier loop's temporary array
      barrierWeightsTemporaryArray.current = [];
      return null
    });
    
    return connections
  };

  useEffect(() => {
    if (AllBarrierWeights.current.length > 0) {
      AllBarrierWeights.current.forEach((data) => {
        dispatch(updateBarrier(data));
      });
      AllBarrierWeights.current = [];
    }
  }, [AllBarrierWeights, dispatch]);

  useEffect(() => {
    if (bowtieWorking) {
      // Update the bowtie with the accumulated threat sum
      const updatedBowtie = { ...bowtieWorking, threatSum: accumulatedThreatSum.current };
      // Perform a single update to the store with the accumulated threatSum value
      dispatch(updateBowtie(updatedBowtie));
    }
  }, [dispatch, bowtieWorking, threats, barriers]);
  
  return (
    <>
      <BarrierArrows blocks={threats} blockType={"threat"} />
      <BarrierArrows blocks={consequences} blockType={"consequence"} />
    </>
  );
}

export default Lines;
