import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import threatService from '../bowties/threatService'

//set initial state
const initialState = {
    threats: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

//create new threat
export const createThreat = createAsyncThunk(
    'threats/create', 
    async (threatData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await threatService.createThreat(threatData, token)
    } catch (error) {
        const message = (error.response && 
            error.response.data &&
            error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//get threats for a bowtie
export const getThreats = createAsyncThunk(
    'threats/getAllForBowtie',
    async (bowtieId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await threatService.getThreats(bowtieId, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//delete threat
export const deleteThreat = createAsyncThunk(
    'threats/delete',
    async (id, thunkAPI) => {
        try{
            const token = thunkAPI.getState().auth.user.token
            return await threatService.deleteThreat(id, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//update threat
export const updateThreat = createAsyncThunk(
    'threats/update',
    async(threatData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await threatService.updateThreat(threatData, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Threat Slice
export const threatSlice = createSlice({
    name: 'threats',
    initialState,
    reducers: {
        reset:(state) => initialState,
    },
    extraReducers: (builder) => {
        builder
        .addCase(createThreat.pending, (state) => {
            state.isLoading = true
        })
        .addCase(createThreat.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.threats.push(action.payload)
        })
        .addCase(createThreat.rejected, (state, action) =>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getThreats.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getThreats.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.threats = action.payload
        })
        .addCase(getThreats.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteThreat.pending, (state) => {
            state.isLoading = true
        })
        .addCase(deleteThreat.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.threats = state.threats.filter(
                (item) => item._id !== action.payload._id)
        })
        .addCase(deleteThreat.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateThreat.pending, (state) => {
            state.isLoading = true
        })
        .addCase(updateThreat.fulfilled, (state, action) => {
            const {_id, title, description, likelihood, likelihoodValue} = action.payload
            const existingThreat = state.threats.find(threat => threat._id === _id)
            state.isLoading = false
            state.isSuccess = true
            if (existingThreat) {
                existingThreat.title = title
                existingThreat.description = description
                existingThreat.likelihood = likelihood
                existingThreat.likelihoodValue = likelihoodValue
            }
        })
        .addCase(updateThreat.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    }
})

export const {reset} = threatSlice.actions
export default threatSlice.reducer
