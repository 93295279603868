export const stringShortener = (string, blockWidth) => {
    const charLimit = blockWidth / 9;
    if (!string) {
      return "";
    } else if (blockWidth < 120) {
      return "";
    } else if (string.length > charLimit) {
      return string.substring(0, charLimit - 2).concat("...");
    } else {
      return string;
    }
  };