import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { updateBowtie } from "../features/bowties/bowtieSlice";
import BowtieIconOrange from '../components/icons/BowtieIconOrange'

function EditBowtieModal(props) {

  let bowtie = useSelector((state) =>
    state.bowties.bowties.find((bowtie) => bowtie._id === props.bowtieId)
  );

  const dispatch = useDispatch();

  const [editedBowtie, setEditedBowtie] = useState(bowtie);
  const [titleError, setTitleError] = useState(false)
  const [eventError, setEventError] = useState(false)

  const handleClose = () => {
    props.setOpenBowtieModal(false);
    bowtie = "";
    setEditedBowtie({})
  };

  const handleChangeTitle = (e) => {
    setTitleError(false)
    setEditedBowtie({
      ...editedBowtie,
      title: e.target.value,
    });
  };

  const handleChangeDescription = (e) => {
    setEditedBowtie({
      ...editedBowtie,
      description: e.target.value,
    });
  };

  const handleChangeEvent = (e) => {
    setEventError(false)
    setEditedBowtie({
      ...editedBowtie,
      event: e.target.value,
    });
  };


  const handleSave = () => {
    //clean up
    setTitleError(false)
    setEventError(false)

    if(editedBowtie.title === "") {
      setTitleError(true)
    } else if (editedBowtie.event === ""){
      setEventError(true)
    } else {
    dispatch(updateBowtie(editedBowtie));
    handleClose();
    }
  };

  return (
    <div>
      <Dialog open={props.openBowtieModal} onClose={handleClose}>
        <DialogTitle>{editedBowtie.title}
        <BowtieIconOrange sx={{ position:'absolute', top:20, right:20 }} />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id={"title" + props.bowtieId}
            label="Title"
            fullWidth
            required
            error={titleError}
            name="title"
            variant="outlined"
            onChange={handleChangeTitle}
            defaultValue={bowtie.title ? bowtie.title : ""}
            helperText={titleError?"required":""}
          />
          <TextField
            margin="dense"
            id={"description" + props.bowtieId}
            label="Description"
            fullWidth
            multiline
            rows={4}
            name="description"
            variant="outlined"
            onChange={handleChangeDescription}
            defaultValue={bowtie.description ? bowtie.description : ""}
          />
          <TextField
            margin="dense"
            id={"event" + props.bowtieId}
            label="Event"
            required
            error={eventError}
            fullWidth
            name="event"
            variant="outlined"
            onChange={handleChangeEvent}
            defaultValue={bowtie.event ? bowtie.event : ""}
            helperText={eventError?"required":""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary" variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditBowtieModal;
