import { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CssBaseline,
  Grid,
  Typography,
  GlobalStyles,
  Container,
  CircularProgress,
} from "@mui/material/";
import StarIcon from "@mui/icons-material/StarBorder";
import { useNavigate } from "react-router-dom";

function PricingContent() {
  document.body.style.overflow = "auto";
  const [prices, setPrices] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrices = async () => {
      const { prices } = await fetch("/api/payments/config").then((r) =>
        r.json()
      );
      setPrices(prices);
      //console.log(prices);
    };
    fetchPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSubscription = async (priceId) => {
    const { subscriptionId, clientSecret, amount, recurrence, productName } =
      await fetch("/api/payments/create-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          priceId,
        }),
      }).then((response) => {
        return response.json();
      });

    setSubscriptionData({
      subscriptionId,
      clientSecret,
      amount,
      recurrence,
      productName,
    });
  };

  if (subscriptionData) {
    navigate("/subscribe", { state: subscriptionData });
  }

  let sortedByPrice = prices.sort((p1, p2) =>
    p1.unit_amount > p2.unit_amount
      ? 1
      : p1.unit_amount < p2.unit_amount
      ? -1
      : 0
  );

  if (!prices) {
    return <CircularProgress />;
  }

  return (
    <Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />

      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
        >
          Start building bowties and managing your hazards today. Choose a plan
          that meets your needs.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} justifyContent="center" alignItems="center">
          {/* {prices.map((price) => ( */}
          {sortedByPrice.map((price) => (
            // Pro card is full width at sm breakpoint
            <Grid
              item
              key={price.product.name}
              xs={12}
              sm={price.product.name === "Pro" ? 12 : 6}
              md={4}
            >
              <Card
                sx={{
                  ":hover": {
                    boxShadow: 10,
                  },
                }}
              >
                <CardHeader
                  title={price.product.name}
                  subheader={price.product.description}
                  titleTypographyProps={{ align: "center" }}
                  action={
                    price.product.name === "Bowtie Analyst" ? <StarIcon /> : null
                  }
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: "#e0eafb",
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      {price.currency === "usd"
                        ? "$"
                        : price.currency === "gbp"
                        ? "£"
                        : price.currency}
                      {price.unit_amount / 100}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      {price.recurring ? "/" + price.recurring.interval : ""}
                    </Typography>
                  </Box>
                  <ul>
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={1}
                    >
                      {price.product.metadata["1"]}
                    </Typography>
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={2}
                    >
                      {price.product.metadata["2"]}
                    </Typography>
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={3}
                    >
                      {price.product.metadata["3"]}
                    </Typography>
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={
                      price.product.name === "Bowtie Analyst"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={
                      price.type === "recurring"
                        ? () => createSubscription(price.id)
                        : price.type === "one_time"
                        ? () =>
                            navigate("/payment", {
                              state: {
                                priceId: price.id,
                              },
                            })
                        : null
                    }
                  >
                    {price.product.metadata["button"]}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
