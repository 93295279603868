import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import passwordService from './passwordService'


const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

//Forgot password token generation
export const forgot = createAsyncThunk('password/forgot', async (email, thunkAPI) => {
    try {
        return await passwordService.forgot(email)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//Log In
export const reset = createAsyncThunk('password/reset', async (password, thunkAPI) => {
    try {
        return await passwordService.reset(password)
    } catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//Password Slice
export const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(forgot.pending, (state) => {
                state.isLoading = true
            })
            .addCase(forgot.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload
            })
            .addCase(forgot.rejected, (state, action) =>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(reset.pending, (state) => {
                state.isLoading = true
            })
            .addCase(reset.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload
            })
            .addCase(reset.rejected, (state, action) =>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})


export default passwordSlice.reducer