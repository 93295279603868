import axios from 'axios'

const API_URL = '/api/likelihoods/'

//get all likelihoods
const getLikelihoods = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    
    const response = await axios.get(API_URL, config)
    return response.data
}

const likelihoodService = {
    getLikelihoods
}

export default likelihoodService