import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import barrierService from '../bowties/barrierService'

//set initial state
const initialState = {
    barriers: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

//create new barrier
export const createBarrier = createAsyncThunk(
    'barriers/create', 
    async (barrierData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await barrierService.createBarrier(barrierData, token)
    } catch (error) {
        const message = (error.response && 
            error.response.data &&
            error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//get barriers for a bowtie
export const getBarriers = createAsyncThunk(
    'barriers/getAllForBowtie',
    async (bowtieId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await barrierService.getBarriers(bowtieId, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//delete barrier
export const deleteBarrier = createAsyncThunk(
    'barriers/delete',
    async (id, thunkAPI) => {
        try{
            const token = thunkAPI.getState().auth.user.token
            return await barrierService.deleteBarrier(id, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//update barrier
export const updateBarrier = createAsyncThunk(
    'barriers/update',
    async(barrierData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await barrierService.updateBarrier(barrierData, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Barrier Slice
export const barrierSlice = createSlice({
    name: 'barriers',
    initialState,
    reducers: {
        reset:(state) => initialState,
    },
    extraReducers: (builder) => {
        builder
        .addCase(createBarrier.pending, (state) => {
            state.isLoading = true
        })
        .addCase(createBarrier.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.barriers.push(action.payload)
        })
        .addCase(createBarrier.rejected, (state, action) =>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getBarriers.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getBarriers.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.barriers = action.payload
        })
        .addCase(getBarriers.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteBarrier.pending, (state) => {
            state.isLoading = true
        })
        .addCase(deleteBarrier.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.barriers = state.barriers.filter(
                (item) => item._id !== action.payload._id)
        })
        .addCase(deleteBarrier.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateBarrier.pending, (state) => {
            state.isLoading = true
        })
        .addCase(updateBarrier.fulfilled, (state, action) => {
            const {_id, title, description, threat, consequence, quality, condition, weight, position} = action.payload
            const existingBarrier = state.barriers.find(barrier => barrier._id === _id)
            state.isLoading = false
            state.isSuccess = true
            if (existingBarrier) {
                existingBarrier.title = title
                existingBarrier.description = description
                existingBarrier.threat = threat
                existingBarrier.consequence = consequence
                existingBarrier.quality = quality
                existingBarrier.condition = condition
                existingBarrier.weight = weight
                existingBarrier.position = position
            }
        })
        .addCase(updateBarrier.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    }
})

export const {reset} = barrierSlice.actions
export default barrierSlice.reducer
