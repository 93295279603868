import { useSelector } from "react-redux";
import ReactGA from 'react-ga4'
import HomePublic from '../components/HomePublic'
import Dashboard from '../components/Dashboard'

function Home() {

  const { user } = useSelector((state) => state.auth);
  document.body.style.overflow = "auto";

  ReactGA.send({ hitType: "pageview", page: "/home" });

  return (
    <>
      {user ? ( <Dashboard />) : ( <HomePublic />)}
    </>
  );
}

export default Home;
