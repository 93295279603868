import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import BoltIcon from "@mui/icons-material/Bolt";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import HealingIcon from "@mui/icons-material/Healing";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import Tooltip from "@mui/material/Tooltip";

export default function IconButtons(props) {
  const openNewThreat = () => {
    props.setOpenNewThreatModal(true);
  };

  const openNewConsequence = () => {
    props.setOpenNewConsequenceModal(true);
  };

  const openNewThreatBarrier = () => {
    props.setOpenNewThreatBarrierModal(true);
  };

  const openNewConsequenceBarrier = () => {
    props.setOpenNewConsequenceBarrierModal(true);
  };

  return (
    <Stack
      zIndex={"1000"}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Tooltip title="add a new threat">
        <IconButton
          aria-label="add threat"
          sx={{ color: "#6495ED" }}
          onClick={openNewThreat}
        >
          <BoltIcon sx={{ color: "#6495ED" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="add a new preventative barrier">
        <IconButton
          aria-label="add preventative barrier"
          sx={{ color: "#FFD700" }}
          onClick={openNewThreatBarrier}
        >
          <HealthAndSafetyIcon sx={{ color: "#FFD700" }} />
        </IconButton>
      </Tooltip>

      <IconButton aria-label="the event" sx={{ color: "#FFA500" }} disabled>
        <CropSquareIcon sx={{ color: "#FFA500" }} />
      </IconButton>

      <Tooltip title="add a new mitigating barrier">
        <IconButton
          aria-label="add mitigating barrier"
          sx={{ color: "#FFD700" }}
          onClick={openNewConsequenceBarrier}
        >
          <HealingIcon sx={{ color: "#FFD700" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="add a new consequence">
        <IconButton
          aria-label="add consequence"
          sx={{ color: "#FF4E33" }}
          onClick={openNewConsequence}
        >
          <MinorCrashIcon sx={{ color: "#FF4E33" }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
