import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableChartIcon from "@mui/icons-material/TableChart";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, useParams } from "react-router-dom";

export default function IconButtons(props) {
  const navigate = useNavigate();
  const bowtie = useParams();

  return (
    <Stack
      zIndex={"1000"}
      direction="row"
      position="absolute"
      top={64}
      right={5}
      spacing={1}
    >
      <Tooltip title="table view">
        <IconButton
          aria-label="table view"
          onClick={() => navigate("/bowtie-table/" + bowtie.bowtie)}
        >
          <TableChartIcon color="primary" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
