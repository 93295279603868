import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import consequenceService from './consequenceService'

//set initial state
const initialState = {
    consequences: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

//create new consequence
export const createConsequence = createAsyncThunk(
    'consequences/create', 
    async (consequenceData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await consequenceService.createConsequence(consequenceData, token)
    } catch (error) {
        const message = (error.response && 
            error.response.data &&
            error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//get consequences for a bowtie
export const getConsequences = createAsyncThunk(
    'consequences/getAllForBowtie',
    async (bowtieId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await consequenceService.getConsequences(bowtieId, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//delete consequence
export const deleteConsequence = createAsyncThunk(
    'consequences/delete',
    async (id, thunkAPI) => {
        try{
            const token = thunkAPI.getState().auth.user.token
            return await consequenceService.deleteConsequence(id, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//update consequence
export const updateConsequence = createAsyncThunk(
    'consequences/update',
    async(consequenceData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await consequenceService.updateConsequence(consequenceData, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Consequence Slice
export const consequenceSlice = createSlice({
    name: 'consequences',
    initialState,
    reducers: {
        reset:(state) => initialState,
    },
    extraReducers: (builder) => {
        builder
        .addCase(createConsequence.pending, (state) => {
            state.isLoading = true
        })
        .addCase(createConsequence.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.consequences.push(action.payload)
        })
        .addCase(createConsequence.rejected, (state, action) =>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getConsequences.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getConsequences.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.consequences = action.payload
        })
        .addCase(getConsequences.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteConsequence.pending, (state) => {
            state.isLoading = true
        })
        .addCase(deleteConsequence.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.consequences = state.consequences.filter(
                (item) => item._id !== action.payload._id)
        })
        .addCase(deleteConsequence.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateConsequence.pending, (state) => {
            state.isLoading = true
        })
        .addCase(updateConsequence.fulfilled, (state, action) => {
            const {_id, title, description, severity, severityValue} = action.payload
            const existingConsequence = state.consequences.find(consequence => consequence._id === _id)
            state.isLoading = false
            state.isSuccess = true
            if (existingConsequence) {
                existingConsequence.title = title
                existingConsequence.description = description
                existingConsequence.severity = severity
                existingConsequence.severityValue = severityValue
            }
        })
        .addCase(updateConsequence.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    }
})

export const {reset} = consequenceSlice.actions
export default consequenceSlice.reducer
