import axios from 'axios'

const API_URL = '/api/users/'

//Register user
const register = async (userData) => {
    
    const response = await axios.post(API_URL, userData)

    if(response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

//Resend verification email
const resendVerificationEmail = async (email) => {
    const response = await axios.post(API_URL + 'resend-verification', {'email': email})
    return response.data
}

//Verfiy Email Token
const verifyEmail = async (token) => {
    const response = await axios.get(API_URL + 'verify-email', {params: {token: token}})
    return response.data
}

//Log in user
const login = async (userData) => {
    const response = await axios.post(API_URL + 'login', userData)

    if(response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

//Logout User 
const logout = () => {
    localStorage.removeItem('user')
}

//Register user
const newsletterSignup = async (subscriber) => {
    const response = await axios.post('/api/subscriber/', {subscriber})
    if (response) {
        return response.status(200).message('subscribed to newsletter')
    } 
    
}

//functions to export
const authService = {
    register,
    logout,
    login,
    newsletterSignup,
    resendVerificationEmail,
    verifyEmail
}

export default authService