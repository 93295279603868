import React, {useEffect, useRef} from "react";
import Xarrows from "react-xarrows";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateBarrier } from "../features/bowties/barrierSlice";

function Lines() {
  const { bowtie } = useParams();
  const dispatch = useDispatch();

  //get all data from the store
  const bowties = useSelector((state) => state.bowties.bowties);
  const threats = useSelector((state) => state.threats.threats);
  const barriers = useSelector((state) => state.barriers.barriers);
  const consequences = useSelector((state) => state.consequences.consequences);

  //initialise temporary store of barrier weights
  const newCalculatedData = useRef([]);

  //find the bowtie with the id from the url
  const bowtieWorking = bowties.find((item) => {
    return item._id === bowtie;
  });

  const arrowProps = {
    color: "#495464",
    headSize: 0,
    path: "smooth",
    monitorDOMchanges: "true",
    registerEvents: [],
    zIndex: -1000,
  };

  const strokeWidth = 4;

  function BarrierArrows(data) {
    const { blocks, blockType } = data;

    const connections = blocks.map((block, index) => {
      //get all the barriers that belong to the threat or consequence being mapped
      const barrierChildren = barriers.filter((barrier) => {
        return barrier[blockType] === block._id;
      });

      //if there are no barriers for this block, draw a line between the threat/consequence and the bowtie
      //we are ignoring that the line is backwards for a consequence, as there's no arrow head indicating
      //we also add the threat value to the bowtie threatSum
      if (barrierChildren.length === 0) {
        if (blockType === "threat") {
          return (
            <Xarrows
              start={block._id}
              end={bowtieWorking._id}
              startAnchor={"right"}
              endAnchor={"left"}
              key={"arrow_"+block._id + "_" + bowtieWorking._id + "_" + index + "_t"}
              strokeWidth={
                block.likelihoodValue ? block.likelihoodValue : strokeWidth
              }
              {...arrowProps}
            />
          );
        } else if (blockType === "consequence") {
          return (
            <Xarrows
              start={block._id}
              end={bowtieWorking? bowtieWorking._id : null}
              startAnchor={"left"}
              endAnchor={"right"}
              key={"arrow_"+block._id + "_" + bowtieWorking._id + "_" + index + "_c"}
              strokeWidth={
                block.severityValue ? block.severityValue : strokeWidth
              }
              {...arrowProps}
            />
          );
        } else {
          return null;
        }
      } else if (barrierChildren.length === 1) {
        //first handle missing variables:
        const c = barrierChildren[0].condition
          ? 1 - barrierChildren[0].condition / 100
          : 1;
        const q = barrierChildren[0].quality
          ? 1 - barrierChildren[0].quality / 100
          : 1;

        //there is only one barrier so we need to draw two lines
        //THREAT: one between the threat and the barrier and one between the barrier and the bowtie
        if (blockType === "threat") {
          //write the weight of the line between the barrier and the bowtie to the local array for sending to store after this entire map
          newCalculatedData.current.push({_id: barrierChildren[0]._id, weight: block.likelihoodValue * c * q});
          
          return (
            <>
              <Xarrows
                start={block._id}
                end={barrierChildren[0]._id}
                key={"arrow_"+block._id + "_" + barrierChildren[0]._id}
                startAnchor={"right"}
                endAnchor={"left"}
                strokeWidth={block.likelihoodValue ? block.likelihoodValue : strokeWidth}
                {...arrowProps}
              />
              <Xarrows
                start={barrierChildren[0]._id}
                end={bowtieWorking._id}
                key={"arrow_"+barrierChildren[0]._id + "_" + bowtieWorking._id}
                startAnchor={"right"}
                endAnchor={"left"}
                strokeWidth={
                  block.likelihoodValue
                    ? Math.max(block.likelihoodValue * c * q, 0.1)
                    : strokeWidth
                }
                {...arrowProps}
              />
            </>
          );
        } else if (blockType === "consequence") {
          //we are still working in the barriers count of 1 ifs here
          //CONSEQUENCE: one between the bowtie and the barrier and one between the barrier and the consequence
          //TO DO come and replace stroke width once you have summed all the threat lines at the bowtie!
          //TO DO, in here you need to dispatch an update to barrier weight for this solo barrier.
          return (
            <>
              <Xarrows
                start={bowtieWorking._id}
                end={barrierChildren[0]._id}
                key={"arrow_"+barrierChildren[0]._id + "_" + bowtieWorking._id}
                startAnchor={"right"}
                endAnchor={"left"}
                strokeWidth={strokeWidth}
                {...arrowProps}
              />
              <Xarrows
                start={barrierChildren[0]._id}
                end={block._id}
                key={"arrow_"+block._id + "_" + barrierChildren[0]._id}
                startAnchor={"right"}
                endAnchor={"left"}
                strokeWidth={strokeWidth}
                {...arrowProps}
              />
            </>
          );
        } else {
          return null;
        }
      } else if (barrierChildren.length > 1) {
        //there are > 1 barriers so lets draw all of the lines
        //first sort the array based on the barrier.position
        const sortedBarrierChildren = barrierChildren.sort((a, b) => a.position - b.position);

        //then map through this new position sorted array to draw the barriers:
        return sortedBarrierChildren.map((barrier, i, arr) => {
          //ON THE THREAT SIDE
          if (blockType === "threat") {
            const c = barrier.condition ? 1 - barrier.condition / 100 : 1;
            const q = barrier.quality ? 1 - barrier.quality / 100 : 1;
            const weight0 = block.likelihoodValue
              ? block.likelihoodValue * c * q
              : strokeWidth;

            //deal with the first barrier on this line:
              //starts on the threat, has weight equal to threat likelihood, ends on first barrier
              //THIS first barrier has a line equal to the threat likelihood on its left, but its weight we write to the store is the threat likelihood * c * q
            if (i === 0) {
              newCalculatedData.current.push({ _id: barrier._id, weight: weight0 });
              
              return (
                <Xarrows
                  start={block._id}
                  end={barrier._id}
                  key={"arrow_"+block._id + "_" + barrier._id + "_" + i}
                  startAnchor={"right"}
                  endAnchor={"left"}
                  strokeWidth={
                    block.likelihoodValue ? block.likelihoodValue : strokeWidth
                  }
                  {...arrowProps}
                />
              );
            } else if (i !== arr.length - 1 && i !== 0) {
              //middle barriers on the threat side i.e. there are >1 barriers and this one is not first or last
              //NOTE as this is complicated: Here we are calcuating the weight of THIS barrier (i) based on the previous barrier's weight and THIS barriers q and c
                //we do this to write the weight to storage for the next barrier to use. NOTE THAT WE ARE READING AND WRITING TO THE useRef AS WE CAN'T WRITE THESE UPDATES TO STATE DURING RENDER.
                //we draw the lines on the left of the barrier using the previous barrier's weight as the strokeWidth. We must get this from the useRef.
                
                const iMinusOne = newCalculatedData.current.find((item) => item._id === arr[i - 1]._id);
                const weightI = iMinusOne.weight !== undefined && iMinusOne.weight !== null 
                ? iMinusOne.weight * c * q
                : strokeWidth;

              newCalculatedData.current.push({ _id: barrier._id, weight: weightI });
              
              return (
                <Xarrows
                  start={arr[i - 1]._id}
                  end={arr[i]._id}
                  key={"arrow_"+barrier._id + "_" + i}
                  startAnchor={"right"}
                  endAnchor={"left"}
                  strokeWidth={
                    iMinusOne.weight !== undefined && iMinusOne.weight !== null  ? iMinusOne.weight : strokeWidth
                  }
                  {...arrowProps}
                />
              );
            } else if (i === arr.length - 1) {
              //Last barrier on the threat side
                //we have to draw the line between this barrier and its predecessor
                //AND the line between this barrier and the bowtie
              //have to put weightI here again because of scoping
              const iMinusOnePenultimate = newCalculatedData.current.find((item) => item._id === arr[i - 1]._id);
              const weightLast = iMinusOnePenultimate.weight !== undefined && iMinusOnePenultimate.weight !== null ? iMinusOnePenultimate.weight * c * q : strokeWidth;
              newCalculatedData.current.push({ _id: barrier._id, weight: weightLast });

              return (
                <>
                  <Xarrows
                    start={arr[i - 1]._id}
                    end={arr[i]._id}
                    key={"arrow_"+barrier._id + "_" + i}
                    startAnchor={"right"}
                    endAnchor={"left"}
                    strokeWidth={
                      iMinusOnePenultimate.weight !== undefined && iMinusOnePenultimate.weight !== null ? iMinusOnePenultimate.weight : strokeWidth
                    }
                    {...arrowProps}
                  />
                  <Xarrows
                    start={barrier._id}
                    end={bowtieWorking._id}
                    key={"arrow_"+barrier._id + "_" + bowtieWorking._id + "_" + i}
                    startAnchor={"right"}
                    endAnchor={"left"}
                    strokeWidth={weightLast !== undefined && weightLast !== null  ? weightLast : strokeWidth}
                    {...arrowProps}
                  />
                </>
              );
            }
          } else if (blockType === "consequence") {
            if (i === arr.length - 1) {
              //last barrier on the consequence side (these are drawn backwards, so this is the first barrier!)
              //we know there is more than one barrier to end up here, so we need to draw the line
              //between the bowtie and the barrier and the barrier and its predecessor
              return (
                <>
                  <Xarrows
                    start={arr[i - 1]._id}
                    end={arr[i]._id}
                    key={"arrow_"+barrier._id + "_" + i}
                    {...arrowProps}
                  />
                  <Xarrows
                    start={bowtieWorking._id}
                    end={barrier._id}
                    key={"arrow_"+barrier._id + "_" + bowtieWorking._id + "_" + i}
                    startAnchor={"right"}
                    endAnchor={"left"}
                    {...arrowProps}
                  />
                </>
              );
            } else if (i === 0) {
              //first barrier on the consequence side
              return (
                <Xarrows
                  start={barrier._id}
                  end={block._id}
                  key={"arrow_"+barrier._id + "_" + block._id + "_" + i}
                  startAnchor={"right"}
                  endAnchor={"left"}
                  {...arrowProps}
                />
              );
            } else {
              //middle barriers on the consequence side
              return (
                <Xarrows
                  start={arr[i]._id}
                  end={arr[i + 1]._id}
                  key={"arrow_"+barrier._id + "_" + i}
                  {...arrowProps}
                />
              );
            }
          } else {
            return null;
          } return null;
        });
      }
      return null
    });
    
    return connections
  };

  useEffect(() => {
    if (newCalculatedData.current.length > 0) {
      newCalculatedData.current.forEach((data) => {
        dispatch(updateBarrier(data));
      });
      newCalculatedData.current = [];
    }
  }, [newCalculatedData, dispatch]);
  
  return (
    <>
      <BarrierArrows blocks={threats} blockType={"threat"} />
      <BarrierArrows blocks={consequences} blockType={"consequence"} />
    </>
  );
}

export default Lines;
