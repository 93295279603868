import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'
import bowtiesReducer from '../features/bowties/bowtieSlice'
import threatReducer from '../features/bowties/threatSlice'
import barrierReducer from '../features/bowties/barrierSlice'
import consequenceReducer from '../features/bowties/consequenceSlice'
import passwordReducer from '../features/password/passwordSlice'
import severityReducer from '../features/bowties/severitySlice'
import likelihoodReducer from '../features/bowties/likelihoodSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    bowties: bowtiesReducer,
    threats: threatReducer,
    barriers: barrierReducer,
    consequences: consequenceReducer,
    password: passwordReducer,
    severity: severityReducer,
    likelihood: likelihoodReducer,
  },
});
