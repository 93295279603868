import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout } from '../features/auth/authSlice';

const PrivateRoute = ({ children }) => {
    const { user, isSuccess } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    if (!isSuccess) {
        toast.error('Not authorized, please log in');
        return <Navigate to="/login" />;
    }

    if (isSuccess && !user.verified) {
        toast.error('Account not verified, please verify your email');
        dispatch(logout());
        return <Navigate to="/login" />;
    }

    return children ? children : <Outlet />;
};

export default PrivateRoute;