import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  TextField
} from "@mui/material";
import { useDispatch } from "react-redux";
import BarriersHero from "../img/barriers-hero.png";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import StarIcon from "@mui/icons-material/StarBorder";
import { Link } from "react-router-dom";
import { newsletterSignup } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import ReactGA from 'react-ga4'

function HomePublic () {
  const [prices, setPrices] = useState([]);
  const [signupEmail, setSignupEmail] = useState("");
  const [signupEmailFormatError, setSignupEmailFormatError] = useState(false);
  document.body.style.overflow = "auto";

  const dispatch = useDispatch();

  ReactGA.send({ hitType: "pageview", page: "/home" });

  const registerHero = () => {
    ReactGA.event({
      category: "register button",
      action: "register button click: hero",
      label: "register button hero", // optional
    });
  }

  const registerPrice = () => {
    ReactGA.event({
      category: "register button",
      action: "register button click: price",
      label: "register button price", // optional
    });
  }

  useEffect(() => {
    const fetchPrices = async () => {
      const { prices } = await fetch("/api/payments/config").then((r) =>
        r.json()
      );
      setPrices(prices);
    };
    fetchPrices();
  }, []);

  let sortedByPrice = prices.sort((p1, p2) =>
    p1.unit_amount > p2.unit_amount
      ? 1
      : p1.unit_amount < p2.unit_amount
      ? -1
      : 0
  );

  const signupOnChange = (e) => {
    setSignupEmailFormatError(false);
    setSignupEmail((prevState) => ({
      ...prevState,
      signupEmail: e.target.value,
    }));
  };

  //eslint-disable-next-line
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const newsletterSignupSubmit = () => {
    const email = signupEmail.signupEmail
    //clean the form
    setSignupEmailFormatError(false);
    //error check the
    if (!email.match(emailFormat)) {
      setSignupEmailFormatError(true);
    } else {
      dispatch(newsletterSignup(email));
      toast("You are now signed up")
      setSignupEmail("")
      const emailInput = document.getElementById("email");
      emailInput.value = "";
    }
  };

  return (

<Container disableGutters sx={{ minWidth: { xs: "100%", md: "100%" } }}>
          <Grid container>
            <Grid item xs={12} md={12} lg={5} align={"left"}>
              <Typography
                fontSize={"64px"}
                ml={{ xs: 5, md: 10 }}
                mb={{ xs: 2, md: 3 }}
                mt={{ xs: 1, md: 5 }}
                align={"left"}
                lineHeight={"70px"}
                fontFamily={"Roboto"}
                fontWeight={700}
                color={"#5F5F5F"}
              >
                Manage your hazards using Bowtie.app
              </Typography>
              <Button
                sx={{
                  width: 200,
                  mb: 5,
                  ml: { xs: 5, md: 10 },
                  maxWidth: { xs: 1 },
                }}
                variant={"contained"}
                component={Link}
                to={"/register"}
                onClick={() => registerHero()}
              >
                Register
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={7} >
              {/* <Hero /> */}
              <Box
                component="img"
                mt={-3}
                sx={{ maxWidth: { xs: 1, md: 700 } }}
                alt="barriers"
                src={BarriersHero}
              ></Box>
            </Grid>
          </Grid>
          <Container
            disableGutters
            sx={{ minWidth: { xs: "100%", md: "100%" } }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(0deg, rgba(97,169,240,1) 0%, rgba(25,118,210,1) 81%)",
                marginTop: -10,
                paddingTop: 8,
              }}
            >
              <Grid container direction="row">
                <Grid
                  item
                  md={3}
                  p={2}
                  container
                  alignContent={"center"}
                  justifyContent={{ xs: "center", md: "right" }}
                >
                  <ErrorOutlineIcon
                    fontSize="large"
                    sx={{ color: "#FFFFFF" }}
                  />
                </Grid>
                <Grid
                  item
                  md={9}
                  container
                  alignContent={"center"}
                  justifyContent={{ xs: "center", md: "left" }}
                >
                  <Typography color="white" fontSize="2rem" p={2}>
                    Supports effective management of hazards
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid
                  item
                  md={3}
                  p={2}
                  container
                  alignContent={"center"}
                  justifyContent={{ xs: "center", md: "right" }}
                >
                  <PsychologyOutlinedIcon
                    fontSize="large"
                    sx={{ color: "#FFFFFF" }}
                  />
                </Grid>
                <Grid
                  item
                  md={9}
                  container
                  alignContent={"center"}
                  justifyContent={{ xs: "center", md: "left" }}
                >
                  <Typography color="white" fontSize="2rem" p={2}>
                    AI assistance helps you concisely summarise your descriptions
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid
                  item
                  md={3}
                  p={2}
                  container
                  alignContent={"center"}
                  justifyContent={{ xs: "center", md: "right" }}
                >
                  <ModeEditOutlinedIcon
                    fontSize="large"
                    sx={{ color: "#FFFFFF" }}
                  />
                </Grid>
                <Grid
                  item
                  md={9}
                  container
                  alignContent={"center"}
                  justifyContent={{ xs: "center", md: "left" }}
                >
                  <Typography color="white" fontSize="2rem" p={2}>
                    Capture threats, barriers and consequences
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid
                  item
                  md={3}
                  p={2}
                  mb={5}
                  container
                  alignContent={"center"}
                  justifyContent={{ xs: "center", md: "right" }}
                >
                  <AssessmentOutlinedIcon
                    fontSize="large"
                    sx={{ color: "#FFFFFF" }}
                  />
                </Grid>
                <Grid
                  item
                  md={9}
                  mb={5}
                  container
                  alignContent={"center"}
                  justifyContent={{ xs: "center", md: "left" }}
                >
                  <Typography color="white" fontSize="2rem" p={2}>
                    Communicate your risk management plan
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Container
            disableGutters
            maxWidth="sm"
            component="main"
            sx={{ pt: 8, pb: 6 }}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Pricing
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              component="p"
            >
              Start building bowties and managing your hazards today. Choose a
              plan that meets your needs.
            </Typography>
          </Container>
          {/* End hero unit */}

          <Container maxWidth="md" component="main" sx={{ pb: 6 }}>
            <Grid container spacing={5} justifyContent="center" alignItems="center">
              {sortedByPrice.map((price) => (
                // Pro card is full width at sm breakpoint
                <Grid
                  item
                  key={price.product.name}
                  xs={12}
                  sm={price.product.name === "Pro" ? 12 : 6}
                  md={4}
                >
                  <Card
                    sx={{
                      ":hover": {
                        boxShadow: 10,
                      },
                    }}
                  >
                    <CardHeader
                      title={price.product.name}
                      subheader={price.product.description}
                      titleTypographyProps={{ align: "center" }}
                      action={
                        price.product.name === "Pro" ? <StarIcon /> : null
                      }
                      subheaderTypographyProps={{
                        align: "center",
                      }}
                      sx={{
                        backgroundColor: "#e0eafb",
                      }}
                    />
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "baseline",
                          mb: 2,
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h3"
                          color="text.primary"
                        >
                          {price.currency === 'usd' ? '$' : price.currency === 'gbp' ? '£' : price.currency}
                          {price.unit_amount / 100}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                        {price.recurring ? "/" + price.recurring.interval : ''}
                        </Typography>
                      </Box>
                      <ul style={{ paddingLeft: 0, margin: 0 }}>
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={1}
                        >
                          {price.product.metadata["1"]}
                        </Typography>
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={2}
                        >
                          {price.product.metadata["2"]}
                        </Typography>
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={3}
                        >
                          {price.product.metadata["3"]}
                        </Typography>
                      </ul>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Button
              sx={{ m: 3, width: 200 }}
              variant={"contained"}
              component={Link}
              to={"/register"}
              onClick={() => registerPrice()}
            >
              Register
            </Button>
          </Container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={3}
            alignItems="center"
            sx={{
              background:
                "linear-gradient(0deg, rgba(97,169,240,1) 0%, rgba(25,118,210,1) 81%)",

              paddingTop: 6,
              paddingBottom: 6,
            }}
          >
            <Grid item>
              <Typography variant="h5" style={{ color: "white" }}>
                Stay up to date with Bowtie.app
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                placeholder="Enter your email"
                autoComplete="email"
                required
                name="email"
                id="email"
                style={{ backgroundColor: "white", borderRadius: 5 }}
                onChange={signupOnChange}
                error={signupEmailFormatError}
                helperText={signupEmailFormatError?"email format required":""}
              ></TextField>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={newsletterSignupSubmit}
              >
                Send me updates
              </Button>
            </Grid>
          </Grid>
        </Container>
)}

export default HomePublic;