import axios from 'axios'

const API_URL = '/api/bowties/'

//create bowtie
const createBowtie = async (bowtieData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    
    const response = await axios.post(API_URL, bowtieData, config)
    return response.data
}

//update bowtie
const updateBowtie = async (bowtieData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    
    const response = await axios.put(API_URL+bowtieData._id, bowtieData, config)
    return response.data
}

//get all bowties
const getBowties = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    
    const response = await axios.get(API_URL, config)
    return response.data
}

//delete Bowtie
const deleteBowtie = async (bowtieId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    
    const response = await axios.delete(API_URL+bowtieId, config)
    return response.data
}


const bowtieService = {
    createBowtie,
    updateBowtie,
    getBowties,
    deleteBowtie
}

export default bowtieService