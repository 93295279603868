import axios from "axios";

const API_URL = "/api/threats/";

//create threat
const createThreat = async (threatData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, threatData, config);

  return response.data;
};

//update threat
const updateThreat = async (threatData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    API_URL + threatData._id,
    threatData,
    config
  );
  
  return response.data;
};

//get all threats for a bowtie
const getThreats = async (bowtieId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + bowtieId, config);

  return response.data;
};

//delete Threat
const deleteThreat = async (threatId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + threatId, config);
  return response.data;
};

const threatService = {
  createThreat,
  updateThreat,
  getThreats,
  deleteThreat,
};

export default threatService;
