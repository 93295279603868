import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BowtieTable from "../components/BowtieTable";
import BowtieButton from "../components/BowtieButton";
import { useEffect } from "react";
import { getBowties } from "../features/bowties/bowtieSlice";
import {reset} from '../features/auth/authSlice'
import {toast} from 'react-toastify'

function BowtieTablePage() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bowtieParam = useParams();
  
  const { user } = useSelector((state) => state.auth);
  const { bowties , isSuccess} = useSelector((state) => state.bowties); 
  

  if (bowties.length === 0) {
    dispatch(getBowties());
  }

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    if (!bowtieParam) {
      console.log("we didn't find a bowtie param so navigating home");
      navigate("/");
    }

    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line
  }, []);

  //Working bowtie information
  const bowtie = bowties.find((bt) => bt._id === bowtieParam.bowtie);

  if(bowties){
    if(bowties.length > 0 && !bowtie){
      
      toast.error("No bowtie with that id found, taking you home")
      navigate('/')
    }
  }

  if(isSuccess) {
    return (
      <>
        <Typography align="left" m={2} variant="h6">
          {bowtie? bowtie.title: ""}: {bowtie? bowtie.event : ""}
        </Typography>
        <BowtieButton />
        <BowtieTable />;
      </>
    );
  } 
  
}

export default BowtieTablePage;
