import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Container,
  CssBaseline,
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  IconButton,
  Typography,
  Avatar,
  Chip,
} from "@mui/material";
import ArticleOutlined from "@mui/icons-material/ArticleOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

const Account = () => {
  const { user } = useSelector((state) => state.auth);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const { subscriptions } = await fetch("/api/payments/subscriptions", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }).then((r) => r.json());

      setSubscriptions(subscriptions.data);
    };
    fetchSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!subscriptions) {
    return "";
  }

  const cancel = async (subscriptionId) => {
    const { id, status } = await fetch("/api/payments/cancel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({ subscriptionId: subscriptionId }),
    }).then((r) => r.json());
    if (id && status) {
      toast.error("Subscription " + status);
    }
  };

  return (
    <Container component="main" maxWidth="xl">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ mt: 2, bgcolor: "secondary.main" }}>
          <AccountBalanceOutlinedIcon />
        </Avatar>
        <Typography mt={1} component="h1" variant="h5">
          My Account
        </Typography>
        <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent:"space-between", margin:2}}>
          <Grid item>
            <Typography component="h6" variant="h6" >
              Subscriptions
            </Typography>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to="/pricing"
              variant="contained"
            >
              Add a subscription
            </Button>
          </Grid>
        </Grid>
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow:5}}>
          <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Subscription ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Card Last 4 digits</TableCell>
                  <TableCell>Expiry Date</TableCell>
                  <TableCell align="center">Latest Invoice</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions.map((s) => (
                  <TableRow
                    key={s.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Link
                        to={{
                          pathname: `https://dashboard.stripe.com/test/subscriptions/${s.id}`,
                        }}
                      >
                        {s.id}
                      </Link>
                    </TableCell>
                    <TableCell>{s.status}</TableCell>
                    <TableCell>
                      {s.default_payment_method?.card?.last4}
                    </TableCell>
                    <TableCell>
                      {new Date(s.current_period_end * 1000).toString()}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        href={`https://dashboard.stripe.com/test/invoices/${s.latest_invoice}`}
                      >
                        <ArticleOutlined />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {s.status === "active" ? (
                        <Chip
                          component={Button}
                          variant="outlined"
                          color="error"
                          label="cancel"
                          onClick={() => cancel(s.id)}
                        />
                      ) : s.status === "canceled" ? (
                        <Chip
                          
                          label="cancelled"
                          onClick={() => cancel(s.id)}
                        />
                      ) : (
                        <Chip
                          component={Button}
                          color="warning"
                          variant="outlined"
                          label="pay now"
                          onClick={() => ""}
                          href={`https://dashboard.stripe.com/test/invoices/${s.latest_invoice}`}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
};

export default Account;
