import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {  CircularProgress,} from "@mui/material";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function ConsequencesProfile() {
  const { user } = useSelector((state) => state.auth);
  const [graphData, setGraphData] = useState();
  const navigate = useNavigate();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Mitigative Barrier Status",
      },
    },
  };

  
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    fetchConsequenceData();
    // eslint-disable-next-line
  }, []);

  const fetchConsequenceData = async () => {
    const { consequencesProfile } = await fetch("/api/dashboard", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    }).then((r) => r.json());

    setGraphData({ consequencesProfile });
  };

  if (!user) {
    return null; // Render nothing if no user
  }

  if (!graphData) {
    return <CircularProgress />; // Render loading state if dashboard data is not yet available
  }

  if (graphData.consequencesProfile !== undefined) {
    const labels = graphData.consequencesProfile.map(
      (consequence) => consequence.consequenceTitle
    );

    const data = {
      labels,
      datasets: [
        {
          label: "Severity",
          data: graphData.consequencesProfile.map(
            (consequence) => consequence.severity
          ),
          backgroundColor: "#FF4E33",
        },
        {
          label: "Mitigated Severity",
          data: graphData.consequencesProfile.map(
            (consequence) => consequence.liveSeverity
          ),
          backgroundColor: "#FFAB9C",
        },
      ],
    };

    return <Bar options={options} data={data} />;
  }
}

export default ConsequencesProfile;
