//import axios from 'axios'
const axios = require('axios')

const API_URL = '/api/severities/'

//get all severities
const getSeverities = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    
    const response = await axios.get(API_URL, config)
    return response.data
}

const severityService = {
    getSeverities
}

//export default severityService
module.exports = severityService