import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";

const STRIPE_PUBLISHABLE_KEY="pk_live_9HS7tsS4NDZ3ZlXQnKzcX1FD00OmHrh73q"
//const STRIPE_PUBLISHABLE_KEY = "pk_test_yvfcckKmeySobTz7x6hb06vR00o42TupVn";
//console.log("STRIPE_PUBLISHABLE_KEY", STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
// console.log("stripePromise", stripePromise);

const CheckoutForm = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  const fetchClientSecret = useCallback(async () => {
    const priceId = location.state?.priceId;

  try {
    const response = await fetch("/api/payments/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({ priceId }),
    });

    const data = await response.json();
    console.log("Server response:", data);

    if (!data.clientSecret) {
      throw new Error("Client secret is undefined");
    }

    return data.clientSecret;
  } catch (error) {
    console.error("Error fetching client secret:", error);
    throw error;
  }
}, [location.state?.priceId, user.token]);


  const options = { fetchClientSecret };

  //console.log("options", options);

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
