import React, { useState, useEffect } from "react";
import {
  Navigate,
  //useNavigate
} from "react-router-dom";
import { useSelector } from "react-redux";
// import { toast } from "react-toastify";

const Return = () => {
  const [status, setStatus] = useState(null);
  //const [customerEmail, setCustomerEmail] = useState('');
  //const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    //console.log("queryString", queryString);
    //console.log("urlParams", urlParams);
    //console.log("sessionId", sessionId);

    fetch(`/api/payments/session-status?session_id=${sessionId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        //setCustomerEmail(data.customer_email);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === "open") {
    return <Navigate to="/payment" />;
  }

  if (status === "complete") {
    // toast(`Purchase for ${customerEmail} complete! Enjoy using Bowtie.app`)
    return <Navigate to="/" />;
  }

  return null;
};

export default Return;
