import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import axios from "axios";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://bowtie.app/">
        Bowtie.app
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function ResetPassword() {
  //const [validUrl, setValidUrl] = useState(false);
  const [passwordError, setPasswordError] = useState(false)
  const [password2Error, setPassword2Error] = useState(false)
  const [formData, setFormData] = useState({
    password: "",
    password2: ""
  })
  const [msg, setMsg] = useState("")
  const [error, setError] = useState("")
  const { user } = useSelector((state) => state.auth);

  const {password, password2} = formData

  const API_URL = '/api/password/'

  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    if (user) {
      toast.info("You are already logged in");
      navigate("/");
    }

  }, [user, navigate]);

  // useEffect(() => {
  //   const verifyUrl = async () => {
  //     try {
  //       await axios.get(url);
  //       console.log("verifyUrl function says the url is ok")
  //       setValidUrl(true)
  //     } catch (error) {
  //       console.log("verifyUrl says the Url is not good")
  //       setValidUrl(false)
  //     }
  //   }
  //   verifyUrl(); 
  // }, [param, url])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    
    setPasswordError(false)
    setPassword2Error(false)

    if (password.length<8){
      setPasswordError(true)
    }
    else if(password !== password2 || password2.length<8){
      setPassword2Error(true)
    } else {
      try {
        const {data} = await axios.post(`http://localhost:8080${API_URL}reset/${param.id}/${param.token}`, {password});
        toast(data.message)
        setError("")
        
        if(data) {
          navigate("/login")
        }
        
      } catch (error) {
        setMsg("")
        setError(error)
      }

  }
    
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {true ? (
        <Box
        sx={{
          marginTop: { xs: 2, sm: 8 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockResetIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Typography component="p" variant="body">
          Please provide a new password
        </Typography>
        <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            label="New password"
            name="password"
            autoFocus
            onChange={onChange}
            error={passwordError}
            helperText={passwordError?"minimum 8 characters":""}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            id="password2"
            label="Verify new password"
            name="password2"
            onChange={onChange}
            error={password2Error}
            helperText={password2Error?"passwords do not match":""}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          <Grid container sx={{flexDirection: "row"}}>
            {msg && <Grid item><Typography sx={{}}>{msg}</Typography></Grid>}
            {error && <Grid item><Typography sx={{}}>{error}</Typography></Grid>}
          </Grid>
        </Box>
      </Box>
      ) : (
        <Box
        sx={{
          marginTop: { xs: 2, sm: 8 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockResetIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Invalid Reset Link
        </Typography>
        <Typography component="p" variant="body">
          Please <Link to="/forgot">try again</Link>
        </Typography>
        </Box>
      ) }
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

export default ResetPassword;
