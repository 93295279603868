import { SvgIcon } from "@mui/material";

export default function BowtieIcon(props) {
    return (
      <SvgIcon {...props}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M24 0H0V5.19336L12 11.0276L24 5.19336V0ZM24 18.8066L12 12.9724L0 18.8066V24H24V18.8066Z" fill="white"/>
        </svg>
      </SvgIcon>
    );
  }
  