import React from 'react';
import Toolbox from '../components/Toolbox';
import BowtieTableButton from '../components/BowtieTableButton';
import Canvas from '../components/Canvas';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import NewThreatModal from '../components/NewThreatModal';
import NewConsequenceModal from '../components/NewConsequenceModal';
import NewThreatBarrierModal from '../components/NewThreatBarrierModal';
import NewConsequenceBarrierModal from '../components/NewConsequenceBarrierModal';
import Lines from '../components/CanvasLines';
import { useXarrow } from 'react-xarrows';
import { useSelector } from 'react-redux';


function Editor() {

  const [openNewThreatModal, setOpenNewThreatModal] = React.useState(false)
  const [openNewConsequenceModal, setOpenNewConsequenceModal] = React.useState(false)
  const [openNewThreatBarrierModal, setOpenNewThreatBarrierModal] = React.useState(false)
  const [openNewConsequenceBarrierModal, setOpenNewConsequenceBarrierModal] = React.useState(false)

  const updateXarrow = useXarrow();

  const bowties = useSelector((state) => state.bowties)

  return (
    <>
      <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          onPanningStop={updateXarrow}
          onWheelStop={updateXarrow}
          onZoomStop={updateXarrow}
          limitToBounds={true}
          onTransformed={updateXarrow}
        >
          {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => {
            
            return (
              (
                <React.Fragment>
                  <BowtieTableButton />
                  <div className="toolbox">
                    <Toolbox openNewThreatModal={openNewThreatModal} setOpenNewThreatModal={setOpenNewThreatModal}
                    openNewConsequenceModal={openNewConsequenceModal} setOpenNewConsequenceModal={setOpenNewConsequenceModal}
                    openNewThreatBarrierModal={openNewThreatBarrierModal} setOpenNewThreatBarrierModal={setOpenNewThreatBarrierModal}
                    openNewConsequenceBarrierModal={openNewConsequenceBarrierModal} setOpenNewConsequenceBarrierModal={setOpenNewConsequenceBarrierModal}/>
                    {bowties.isSuccess ? <><NewThreatModal openNewThreatModal={openNewThreatModal} setOpenNewThreatModal={setOpenNewThreatModal}/>
                    <NewConsequenceModal openNewConsequenceModal={openNewConsequenceModal} setOpenNewConsequenceModal={setOpenNewConsequenceModal}/>
                    <NewThreatBarrierModal openNewThreatBarrierModal={openNewThreatBarrierModal} setOpenNewThreatBarrierModal={setOpenNewThreatBarrierModal}/>
                    <NewConsequenceBarrierModal openNewConsequenceBarrierModal={openNewConsequenceBarrierModal} setOpenNewConsequenceBarrierModal={setOpenNewConsequenceBarrierModal}/> </> : null}
                  </div>
                  <div className="zoombox">
                  <Stack spacing={1} direction="row">
                    <Tooltip title="zoom in">
                      <IconButton variant="contained" color="primary" onClick={() => zoomIn()}><ZoomInIcon/></IconButton>
                    </Tooltip>
                    <Tooltip title="zoom out">
                      <IconButton variant="contained" color="primary" onClick={() => zoomOut()}><ZoomOutIcon/></IconButton>
                    </Tooltip>
                    <Tooltip title="center">
                      <IconButton variant="contained" color="primary" onClick={() => centerView()}><CenterFocusStrongIcon/></IconButton>
                    </Tooltip>
                    <Tooltip title="zoom extents">
                      <IconButton variant="contained" color="primary" onClick={() => resetTransform()}><ZoomOutMapIcon/></IconButton>
                    </Tooltip>
                    </Stack>
                  </div>
                  <TransformComponent>
                    <Canvas />
                  </TransformComponent>
                </React.Fragment>
              )
            )
          }}
        </TransformWrapper>
        
        <Lines />
        
        
    </>
  )
}

export default Editor