import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bowtieService from '../bowties/bowtieService'

const bowties = JSON.parse(localStorage.getItem('bowties'))

//set initial state
const initialState = {
    bowties: bowties ? bowties : [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

//create new bowtie
export const createBowtie = createAsyncThunk(
    'bowties/create', 
    async (bowtieData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
       return await bowtieService.createBowtie(bowtieData, token)
    } catch (error) {
        const message = (error.response && 
            error.response.data &&
            error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//get bowties
export const getBowties = createAsyncThunk(
    'bowties/getAll', 
    async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await bowtieService.getBowties(token)
    } catch (error) {
        const message = (error.response && 
            error.response.data && 
            error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//delete bowtie
export const deleteBowtie = createAsyncThunk(
    'bowties/delete', 
    async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
       return await bowtieService.deleteBowtie(id, token)
    } catch (error) {
        const message = (error.response && 
            error.response.data && 
            error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//update bowtie
export const updateBowtie = createAsyncThunk(
    'bowties/update',
    async (bowtieData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await bowtieService.updateBowtie(bowtieData, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Bowtie Slice
export const bowtieSlice = createSlice({
    name: 'bowties',
    initialState,
    reducers: {
        reset:(state) => initialState,
    },
    extraReducers: (builder) => {
            builder
            .addCase(createBowtie.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createBowtie.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bowties.push(action.payload)
            })
            .addCase(createBowtie.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getBowties.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBowties.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bowties = action.payload
            })
            .addCase(getBowties.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteBowtie.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteBowtie.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bowties = state.bowties.filter(
                    (item) => item._id !== action.payload._id)
            })
            .addCase(deleteBowtie.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateBowtie.pending, (state) =>{
                state.isLoading = true
            })
            .addCase(updateBowtie.fulfilled, (state, action) => {
                const {_id, title, description, event, threatSum} = action.payload
                const existingBowtie = state.bowties.find(bowtie => bowtie._id === _id)
                state.isLoading = false
                state.isSuccess = true
                if (existingBowtie) {
                    existingBowtie.title = title
                    existingBowtie.description = description
                    existingBowtie.event = event
                    existingBowtie.threatSum = threatSum
                }
            })
            .addCase(updateBowtie.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
        }
    }    
)

export const {reset} = bowtieSlice.actions
export default bowtieSlice.reducer