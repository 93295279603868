import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { createBarrier, updateBarrier } from "../features/bowties/barrierSlice";
import { useParams } from "react-router-dom";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { FormHelperText } from "@mui/material";

function NewThreatBarrierModal(props) {
  const { bowtie } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [aiButton, setAiButton] = useState(true);
  const [positionDropdown, setPositionDropdown] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [threatError, setThreatError] = useState(false);
  const [positionError, setPositionError] = useState(false);

  const threats = useSelector((state) => state.threats.threats);
  const barriers = useSelector((state) => state.barriers.barriers);

  const initial = {
    title: "",
    description: "",
    threat: "",
    bowtie: bowtie,
    condition: "",
    quality: "",
    weight: 0,
    position: "",
  };

  const [newThreatBarrier, setNewThreatBarrier] = useState(initial);
  const [threat, setThreat] = useState("");

  useEffect(() => {
    newThreatBarrier.description === ""
      ? setAiButton(true)
      : setAiButton(false);
  }, [newThreatBarrier.description]);

  const handleClose = () => {
    props.setOpenNewThreatBarrierModal(false);
    setAiButton(true);
    setNewThreatBarrier(initial);
    setPositionDropdown(true);
    setPositionError(false);
  };

  const threatSelect = threats.map((threat) => (
    <MenuItem value={threat._id} key={threat._id}>
      {threat.title}
    </MenuItem>
  ));

  //return the count of barriers for a given threat
  const positionLength = barriers.filter(
    (item) => item.threat === threat
  ).length;

  const positionsSelect = positionLength > 0 ? (
    [
      ...Array.from({ length: positionLength }, (_, i) => (
        <MenuItem value={i + 1} key={i + 1}>
          {i + 1}
        </MenuItem>
      )),
      <MenuItem value={positionLength + 1} key={positionLength + 1}>
        {positionLength + 1}
      </MenuItem>
    ]
  ) : (
    [<MenuItem value={1} key={1}>1</MenuItem>]
  );

  const handleChangeTitle = (e) => {
    setTitleError(false);
    setNewThreatBarrier({
      ...newThreatBarrier,
      title: e.target.value,
    });
  };

  const handleChangeDescription = (e) => {
    setNewThreatBarrier({
      ...newThreatBarrier,
      description: e.target.value,
    });
  };

  const handleChangeThreat = (e) => {
    setThreatError(false);
    setThreat(e.target.value);
    setNewThreatBarrier({
      ...newThreatBarrier,
      threat: e.target.value,
      position: '',
    });
    setPositionDropdown(false);
  };

  const min = 0;
  const max = 100;

  const handleChangeCondition = (e) => {
    const conditionPercentage = Math.max(
      min,
      Math.min(max, Number(e.target.value))
    );
    setNewThreatBarrier({
      ...newThreatBarrier,
      condition: conditionPercentage,
    });
  };

  const handleChangeQuality = (e) => {
    const qualityPercentage = Math.max(
      min,
      Math.min(max, Number(e.target.value))
    );
    setNewThreatBarrier({
      ...newThreatBarrier,
      quality: qualityPercentage,
    });
  };

  const handleChangePosition = (e) => {
    setPositionError(false);
    //set the position of the new barrier
    setNewThreatBarrier({
      ...newThreatBarrier,
      position: e.target.value,
    });
  };

  const generateThreatTitle = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setAiButton(true);
    const response = await axios.post(
      "/api/ai/threat",
      { description: newThreatBarrier.description },
      config
    );
    setAiButton(false);
    //fake an onChange event from the DOM to piggy back the handler, so as to avoid invoking a controlled input by setting its value
    const newObj = { target: { value: response.data } };
    handleChangeTitle(newObj);
  };

  const dispatch = useDispatch();

  const handleSave = () => {
    //clean up
    setTitleError(false);
    setThreatError(false);
    setPositionError(false);

    if (newThreatBarrier.title === "") {
      setTitleError(true);
    } else if (newThreatBarrier.threat === "") {
      setThreatError(true);
    } else if (newThreatBarrier.position === null || newThreatBarrier.position === undefined || newThreatBarrier.position === "") {
      setPositionError(true);
    } else {
      // Step 1: Update the other barriers positions for those barriers that belong to this threat
      const updatedBarriers = barriers
        .filter((item) => item.threat === newThreatBarrier.threat)
        // First, assign positions to barriers with position === null or undefined
        .map((item, index) => {
          if (item.position === null || item.position === undefined) {
            return { ...item, position: index + 1 };
          }
          return item;
        })
        // Then, shift any barriers with position >= this barrier to the right
        .map((item) => {
          if (item.position >= newThreatBarrier.position) {
            return { ...item, position: item.position + 1 };
          }
          return item;
        });

      // Step 2: Dispatch Redux actions to update the store based on the new array
      updatedBarriers.forEach((item) => {
        dispatch(updateBarrier(item)); 
      });
      dispatch(createBarrier(newThreatBarrier));
      handleClose();
    }
  };

  return (
    <Dialog
      open={props.openNewThreatBarrierModal}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>
        <HealthAndSafetyIcon
          sx={{ color: "#FFD700", position: "absolute", top: 20, right: 20 }}
        />
        Create a new Preventative Barrier
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <TextField
              autoFocus
              key={"threatbarriertitle" + bowtie}
              margin="dense"
              label="Title"
              fullWidth
              required
              error={titleError}
              helperText={titleError ? "required" : ""}
              name="title"
              variant="outlined"
              onChange={handleChangeTitle}
              placeholder={"Please provide a title for this barrier"}
              value={newThreatBarrier.title}
            />
          </Grid>
          <Grid item xs={2} alignItems="stretch" style={{ display: "flex" }}>
            <Tooltip
              title={
                !aiButton
                  ? "use AI 🤖 to convert your description into a concise summarised title"
                  : ""
              }
            >
              <Button
                variant="contained"
                name="generate ai summary title"
                color="secondary"
                onClick={generateThreatTitle}
                disabled={aiButton}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  minWidth: "100%",
                  minHeight: "100%",
                }}
              >
                <PsychologyOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              key={"threatbarrierdescription" + bowtie}
              label="Description"
              fullWidth
              multiline
              rows={4}
              name="description"
              variant="outlined"
              onChange={handleChangeDescription}
              placeholder={"Please provide a description of this barrier"}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <FormControl required fullWidth>
              <InputLabel error={threatError} id="threat-label">
                Threat
              </InputLabel>
              <Select
                labelId="threat-select-label"
                id="threat-select"
                value={newThreatBarrier.threat}
                label="Threat"
                onChange={handleChangeThreat}
                required
                error={threatError}
              >
                {threatSelect}
              </Select>
              {threatError ? (
                <FormHelperText error>required</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} mt={1.5}>
            <TextField
              label="Control Quality"
              id="control-quality"
              type="number"
              fullWidth
              name="quality"
              placeholder={"How reliable is this barrier?"}
              onChange={handleChangeQuality}
              value={newThreatBarrier.quality}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6} mt={1.5}>
            <TextField
              label="Control Condition"
              id="control-condition"
              type="number"
              fullWidth
              name="condition"
              placeholder={"Barrier condition right now?"}
              onChange={handleChangeCondition}
              value={newThreatBarrier.condition}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} mt={1.5}>
            <FormControl required fullWidth focused={newThreatBarrier.threat !== "" && positionLength === 0 ? true : undefined}>
              <InputLabel id="position-label" error={positionError} shrink={newThreatBarrier.threat !== "" && positionLength === 0 ? true : undefined}>
                Position
              </InputLabel>
              <Select
                labelId="position-label"
                id="position-select"
                value={newThreatBarrier.threat === "" && positionLength === 0 ? 1 : newThreatBarrier.position} 
                label="Position"
                onChange={handleChangePosition}
                disabled={positionDropdown}
                required
                error={positionError}
              >
                {positionsSelect}
              </Select>
              {positionError ? (
                <FormHelperText error>required</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewThreatBarrierModal;
