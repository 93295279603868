import { useState, useEffect } from "react";
import {
  Tooltip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateThreat,
  getThreats,
  deleteThreat,
} from "../features/bowties/threatSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import BoltIcon from "@mui/icons-material/Bolt";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import axios from "axios";

function EditThreatModal(props) {
  let threat = useSelector((state) =>
    state.threats.threats.find((threat) => threat._id === props.threatId)
  );

  const { user } = useSelector((state) => state.auth);
  const { barriers } = useSelector((state) => state.barriers);
  const [editedThreat, setEditedThreat] = useState(threat);
  const [aiButton, setAiButton] = useState(true);
  const [titleError, setTitleError] = useState(false);

  useEffect(() => {
    editedThreat.description === "" ? setAiButton(true) : setAiButton(false);
  }, [editedThreat.description]);

  const handleClose = () => {
    props.setOpenThreatModal(false);
    threat = "";
    setEditedThreat({});
    props.setThreatId("");
    setAiButton(true);
  };

  const handleChangeTitle = (e) => {
    setTitleError(false);
    setEditedThreat({
      ...editedThreat,
      title: e.target.value,
    });
  };

  const handleChangeDescription = (e) => {
    setEditedThreat({
      ...editedThreat,
      description: e.target.value,
    });
  };

  const handleChangeLikelihood = (e) => {
    const selectedLikelihood = likelihoods.likelihoods.find(
      (likelihood) => likelihood._id === e.target.value
    );
    const likelihoodValue = selectedLikelihood
      ? selectedLikelihood.number
      : threat.likelihoodValue
      ? threat.likelihoodValue
      : null;
    setEditedThreat({
      ...editedThreat,
      likelihood: e.target.value,
      likelihoodValue: likelihoodValue,
    });
  };

  const likelihoods = useSelector((state) => state.likelihood);

  const likelihoodSelect = likelihoods.likelihoods.map((likelihood) => (
    <MenuItem value={likelihood._id} key={likelihood._id}>
      {likelihood.number}: {likelihood.likelihood}
    </MenuItem>
  ));

  const dispatch = useDispatch();

  const generateThreatTitle = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setAiButton(true);
    const response = await axios.post(
      "/api/ai/threat",
      { description: editedThreat.description },
      config
    );
    setAiButton(false);
    //fake an onChange event from the DOM to piggy back the handler, so as to avoid invoking a controlled input by setting its value
    const newObj = { target: { value: response.data } };
    handleChangeTitle(newObj);
  };

  const handleSave = () => {
    //clean up
    setTitleError(false);

    if (editedThreat.title === "") {
      setTitleError(true);
    } else {
      dispatch(updateThreat(editedThreat));
      handleClose();
    }
  };

  const handleDelete = () => {
    dispatch(deleteThreat(editedThreat._id));
    dispatch(getThreats(editedThreat.bowtie));
    handleClose();
  };

  const barrierChildren = () => {
    if (
      barriers.filter((barrier) => barrier.threat === props.threatId).length > 0
    ) {
      return (
        <Tooltip title="this threat has barriers and cannot be deleted">
          <span>
            <IconButton disabled>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      );
    }
  };

  return (
    <div>
      <Dialog open={props.openThreatModal} onClose={handleClose} fullWidth>
        <DialogTitle>
          {editedThreat.title}
          <BoltIcon
            sx={{ color: "#6495ED", position: "absolute", top: 20, right: 20 }}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={10}>
              <TextField
                autoFocus
                margin="dense"
                id={"title" + props.threatId}
                label="Title"
                fullWidth
                name="title"
                variant="outlined"
                onChange={handleChangeTitle}
                defaultValue={threat.title ? threat.title : ""}
                required
                error={titleError}
                helperText={titleError ? "required" : ""}
              />
            </Grid>
            <Grid
              item
              xs={2}
              p={1}
              alignItems="stretch"
              style={{ display: "flex" }}
            >
              <Tooltip
                title={
                  !aiButton
                    ? "use AI 🤖 to convert your description into a concise summarised title"
                    : ""
                }
              >
                <Button
                  variant="contained"
                  name="generate ai summary title"
                  color="secondary"
                  onClick={generateThreatTitle}
                  disabled={aiButton}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    minWidth: "100%",
                    minHeight: "100%",
                  }}
                >
                  <PsychologyOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id={"description" + props.threatId}
                label="Description"
                fullWidth
                multiline
                rows={4}
                name="description"
                variant="outlined"
                onChange={handleChangeDescription}
                defaultValue={threat.description ? threat.description : ""}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <FormControl fullWidth>
                <InputLabel id="likelihood-label">Likelihood</InputLabel>
                <Select
                  labelId="likelihood-select-label"
                  id="likelihood-select"
                  value={editedThreat.likelihood ? editedThreat.likelihood : ""}
                  label="Likelihood"
                  onChange={handleChangeLikelihood}
                >
                  {likelihoodSelect}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {barrierChildren()}
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditThreatModal;
