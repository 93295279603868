import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import likelihoodService from './likelihoodService'

//set initial state
const initialState = {
    likelihoods: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

//get all severities
export const getLikelihoods = createAsyncThunk(
    'likelihoods/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await likelihoodService.getLikelihoods(_, token)
        } catch (error) {
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Severity Slice
export const likelihoodSlice = createSlice({
    name: 'likelihoods',
    initialState,
    reducers: {
        reset:(state) => initialState,
    },
    extraReducers: (builder) => {
        builder
        .addCase(getLikelihoods.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getLikelihoods.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.likelihoods = action.payload
        })
        .addCase(getLikelihoods.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    }
})

export const {reset} = likelihoodSlice.actions
export default likelihoodSlice.reducer
