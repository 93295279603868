import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import BowtieIcon from "./icons/BowtieIcon";
import MyFilesModal from "./MyFilesModal";
import Button from "@mui/material/Button";
import NewBowtieModal from "./NewBowtieModal";

function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openNewBowtieModal, setOpenNewBowtieModal] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenNewBowtieModal = () => {
    setOpenNewBowtieModal(true);
    handleCloseNavMenu();
    handleCloseUserMenu();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  //TODO create a newbowtie with empty threats, barriers and consequences
  //using the response data from the creation functions to set parents
  // const newBowtie(bowtieData, threatData, barrierData, consequenceData) {
  // }

  const logoutClicked = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };

  const accountClicked = () => {
    handleCloseNavMenu();
    handleCloseUserMenu();
    navigate("/account");
  };

  const loginClicked = () => {
    handleCloseNavMenu();
    handleCloseUserMenu();
    navigate("/login");
  };

  const registerClicked = () => {
    handleCloseNavMenu();
    handleCloseUserMenu();
    navigate("/register");
  };

  const trainingClicked = () => {
    handleCloseNavMenu();
    handleCloseUserMenu();
    navigateToExternalUrl("https://training.bowtie.app");
  };

  const navigateToExternalUrl = (url) => {
    window.location.href = url;
  };

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <BowtieIcon
              sx={{
                display: { xs: "none", md: "flex" },
                mr: 1,
                color: "white",
              }}
              onClick={() => navigate("/")}
              onMouseOver={() => true}
              style={{ cursor: "pointer" }}
            />
            <Typography
              variant="h6"
              noWrap
              sx={{
                userSelect: "none",
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
              onClick={() => navigate("/")}
              onMouseOver={() => true}
              style={{ cursor: "pointer" }}
            >
              Bowtie
            </Typography>

            {user ? (
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <MenuItem key="My Files" onClick={handleCloseNavMenu}>
                    <MyFilesModal />
                  </MenuItem>
                  <MenuItem key="New Bowtie" onClick={handleOpenNewBowtieModal}>
                    New Bowtie
                  </MenuItem>
                </Menu>
              </Box>
            ) : undefined}

            <BowtieIcon
              sx={{
                display: { xs: "flex", md: "none" },
                mr: 1,
                color: "white",
              }}
              onClick={() => navigate("/")}
              onMouseOver={() => true}
              style={{ cursor: "pointer" }}
            />
            <Typography
              variant="h5"
              noWrap
              sx={{
                userSelect: "none",
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
              onClick={() => navigate("/")}
              onMouseOver={() => true}
              style={{ cursor: "pointer" }}
            >
              Bowtie
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {user ? (
                <>
                  <MenuItem>
                    <MyFilesModal />
                  </MenuItem>
                  <MenuItem key="New Bowtie" onClick={handleOpenNewBowtieModal}>
                    New Bowtie
                  </MenuItem>
                </>
              ) : undefined}
            </Box>

            {user ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="My Account">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="P Herman" src="" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key="My Account" onClick={accountClicked}>
                    <Typography textAlign="center">My Account</Typography>
                  </MenuItem>
                  <MenuItem key="Logout" onClick={logoutClicked}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : undefined}

            {!user ? (
              <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                <Tooltip title="Log in with your existing user account">
                  <Link to="/login">
                    <Button
                      sx={{ color: "white" }}
                      onClick={handleCloseUserMenu}
                    >
                      Login
                    </Button>
                  </Link>
                </Tooltip>
                <Tooltip title="Register a new user account">
                  <Link to="/register">
                    <Button
                      sx={{ color: "white" }}
                      onClick={handleCloseUserMenu}
                    >
                      Register
                    </Button>
                  </Link>
                </Tooltip>
                <Tooltip title="View and sign up to training courses">
                  <a href="https://training.bowtie.app">
                    <Button
                      sx={{ color: "white" }}
                      onClick={handleCloseUserMenu}
                    >
                      Training
                    </Button>
                  </a>
                </Tooltip>
              </Box>
            ) : undefined}

            {!user ? (
              <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <MenuItem key="Log in" onClick={loginClicked}>
                    Login
                  </MenuItem>
                  <MenuItem key="Register" onClick={registerClicked}>
                    Register
                  </MenuItem>
                  <MenuItem key="Training" onClick={trainingClicked}>
                    Training
                  </MenuItem>
                </Menu>
              </Box>
            ) : undefined}
          </Toolbar>
        </Container>
      </AppBar>
      <NewBowtieModal
        openNewBowtieModal={openNewBowtieModal}
        setOpenNewBowtieModal={setOpenNewBowtieModal}
      />
    </>
  );
}

export default Header;
