import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Box,
  Grid,
  CssBaseline,
  Button,
  Avatar,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function SubscribeForm () {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();

  const [userDetails, setUserDetails] = useState({});
  const [messages, _setMessages] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const { firstName, lastName, email } = await fetch(
        "/api/payments/user-details",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      ).then((r) => r.json());
      setUserDetails({ firstName, lastName, email });
    };
    fetchUserDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  //Submit payment
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }
    
    setIsProcessing(true);

    // Use PaymentElement to tokenize payment details
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/account`,
      },
      payment_method_types: ['auto']
    }).then((r) => r.json());

    if (error) {
      // show error and collect new card details.
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setMessage("Payment status: " + paymentIntent.status + "🎉");
    } else {
      setMessage(paymentIntent.status);
    }

    setIsProcessing(false);
  };

  // console.log("location", location);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: 10,
          p: 3,
          borderRadius: 5,
          background: "white",
        }}
        >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <PaymentIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Subscribe
        </Typography>

        
        <Box component="form" id="payment-form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                {userDetails.firstName} {userDetails.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{userDetails.email}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "medium" }}>
                {/* {location.state.productName}: £{location.state.amount / 100}/ */}
                {/* TO BE IMPROVED BY TAKING PRODUCT NAME AND CURRENCY FROM SESSION */}
                US${location.state.amount / 100}/
                {location.state.recurrence}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <PaymentElement id="payment-element" />
            </Grid>
            <Button
              disabled={isProcessing}
              id="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              <span id="button-text">
                {isProcessing ? "Processing..." : "Pay now"}
              </span>
            </Button>
            <Typography>{messages}</Typography>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
