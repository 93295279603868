import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateBarrier,
  deleteBarrier,
} from "../features/bowties/barrierSlice";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import HealingIcon from "@mui/icons-material/Healing";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";

function EditConsequenceBarrierModal(props) {
  const { bowtie } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [aiButton, setAiButton] = useState(true);
  const [titleError, setTitleError] = useState(false)

  let consequenceBarrier = useSelector((state) =>
    state.barriers.barriers.find(
      (barrier) => barrier._id === props.consequenceBarrierId
    )
  );

  const [editedConsequenceBarrier, setEditedConsequenceBarrier] =
    useState(consequenceBarrier);

  useEffect(() => {
    editedConsequenceBarrier.description === ""
      ? setAiButton(true)
      : setAiButton(false);
  }, [editedConsequenceBarrier.description]);

  const handleClose = () => {
    props.setOpenConsequenceBarrierModal(false);
    consequenceBarrier = "";
    setEditedConsequenceBarrier({});
    props.setConsequenceBarrierId("");
    setAiButton(true);
  };

  const consequences = useSelector((state) =>
    state.consequences.consequences.filter(
      (consequence) => consequence.bowtie === bowtie
    )
  );

  const consequenceSelect = consequences.map((consequence) => (
    <MenuItem value={consequence._id} key={consequence._id}>
      {consequence.title}
    </MenuItem>
  ));

  const handleChangeTitle = (e) => {
    setTitleError(false)
    setEditedConsequenceBarrier({
      ...editedConsequenceBarrier,
      title: e.target.value,
    });
  };

  const handleChangeConsequence = (e) => {
    setEditedConsequenceBarrier({
      ...editedConsequenceBarrier,
      consequence: e.target.value,
    });
  };

  const handleChangeDescription = (e) => {
    setEditedConsequenceBarrier({
      ...editedConsequenceBarrier,
      description: e.target.value,
    });
  };

  const min = 0;
  const max = 100;

  const handleChangeCondition = (e) => {
    const conditionPercentage = Math.max(min, Math.min(max, Number(e.target.value)));
    setEditedConsequenceBarrier({
      ...editedConsequenceBarrier,
      condition: conditionPercentage,
    });
  };

  const handleChangeQuality = (e) => {
    const qualityPercentage = Math.max(min, Math.min(max, Number(e.target.value)));
    setEditedConsequenceBarrier({
    ...editedConsequenceBarrier,
      quality: qualityPercentage,
    });
  };

  const generateConsequenceBarrierTitle = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setAiButton(true);
    const response = await axios.post(
      "/api/ai/consequence-barrier",
      { description: editedConsequenceBarrier.description },
      config
    );
    setAiButton(false);
    //fake an onChange event from the DOM to piggy back the handler, so as to avoid invoking a controlled input by setting its value
    const newObj = { target: { value: response.data } };
    handleChangeTitle(newObj);
  };

  const dispatch = useDispatch();

  const handleSave = () => {
    //clean up
    setTitleError(false)

    if(editedConsequenceBarrier.title === ""){
      setTitleError(true)
    } else {
      dispatch(updateBarrier(editedConsequenceBarrier));
      handleClose();
    }
    
  };

  const handleDelete = () => {
    dispatch(deleteBarrier(editedConsequenceBarrier._id));
    handleClose();
  };

  return (
    <div>
      <Dialog open={props.openConsequenceBarrierModal} onClose={handleClose}>
        <DialogTitle>
          {editedConsequenceBarrier.title}
          <HealingIcon
            sx={{ color: "#FFD700", position: "absolute", top: 20, right: 20 }}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <TextField
                autoFocus
                margin="dense"
                id={"title" + props.consequenceBarrierId}
                label="Title"
                fullWidth
                name="title"
                error={titleError}
                helperText={titleError?"required":""}
                variant="outlined"
                placeholder={"Please provide a title for this barrier"}
                onChange={handleChangeTitle}
                value={editedConsequenceBarrier.title}
              />
            </Grid>
            <Grid
              item
              xs={2}
              alignItems="stretch"
              style={{ display: "flex" }}
            >
              <Tooltip
                title={
                  !aiButton
                    ? "use AI 🤖 to convert your description into a concise summarised title"
                    : ""
                }
              >
                <Button
                  variant="contained"
                  name="generate ai summary title"
                  color="secondary"
                  onClick={generateConsequenceBarrierTitle}
                  disabled={aiButton}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    minWidth: "100%",
                    minHeight: "100%",
                  }}
                >
                  <PsychologyOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id={"description" + props.threatBarrierId}
                label="Description"
                fullWidth
                multiline
                rows={4}
                name="description"
                variant="outlined"
                onChange={handleChangeDescription}
                value={editedConsequenceBarrier.description}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
                <FormControl fullWidth>
                  <InputLabel id="consequence-select-label">
                    Consequence
                  </InputLabel>
                  <Select
                    labelId="consequence-select-id-label"
                    id="consequence-select"
                    value={editedConsequenceBarrier.consequence}
                    label="Consequence"
                    name="consequence"
                    onChange={handleChangeConsequence}
                  >
                    {consequenceSelect}
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} mt={1.5}>
            <TextField
              label="Control Quality"
              value={editedConsequenceBarrier.quality}
              id="control-quality"
              type="number"
              name="quality"
              fullWidth
              placeholder={"How reliable is this barrier?"}
              onChange={handleChangeQuality}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6} mt={1.5}>
            <TextField
              label="Control Condition"
              id="control-condition"
              type="number"
              fullWidth
              name="condition"
              placeholder={"Barrier condition right now?"}
              onChange={handleChangeCondition}
              value={editedConsequenceBarrier.condition}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditConsequenceBarrierModal;
