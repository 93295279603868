import axios from 'axios'

const API_URL = '/api/password/'

const forgot = async (email) => {
    const response = await axios.post(API_URL + 'forgot', email)
    return response.data
}

const reset = async (password) => {
    const response = await axios.get(API_URL + 'reset', password)
    return response.data
}


const passwordService = {forgot, reset}

export default passwordService