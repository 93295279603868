import axios from 'axios'

const API_URL = '/api/barriers/'

//create barrier
const createBarrier = async (barrierData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }

    const response = await axios.post(API_URL, barrierData, config)
    return response.data
}

//update barrier
const updateBarrier = async (barrierData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    
    const response = await axios.put(API_URL+barrierData._id, barrierData, config)
    return response.data

}

//get all barriers for a bowtie
const getBarriers = async (bowtieId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }

    const response = await axios.get(API_URL+bowtieId, config)
    return response.data
}

//delete barrier
const deleteBarrier = async (barrierId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }

    const response = await axios.delete(API_URL+barrierId, config)
    return response.data
}


const barrierService = {
    createBarrier,
    getBarriers,
    updateBarrier,
    deleteBarrier
}

export default barrierService