import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import EditThreatModal from "./EditThreatModal";
import EditConsequenceModal from "./EditConsequenceModal";
import EditThreatBarrierModal from "./EditThreatBarrierModal";
import EditConsequenceBarrierModal from "./EditConsequenceBarrierModal";
import { getBowties } from "../features/bowties/bowtieSlice";
import { getThreats, updateThreat } from "../features/bowties/threatSlice";
import { getBarriers } from "../features/bowties/barrierSlice";
import { getConsequences } from "../features/bowties/consequenceSlice";
import EditBowtieModal from "./EditBowtieModal";
import { getSeverities } from "../features/bowties/severitySlice";
import { getLikelihoods } from "../features/bowties/likelihoodSlice";
import { stringShortener } from "../utils/utils";
import { toast } from "react-toastify";

function Canvas() {
  const [openBowtieModal, setOpenBowtieModal] = useState(false);
  const [bowtieId, setBowtieId] = useState();

  const [openThreatModal, setOpenThreatModal] = useState(false);
  const [threatId, setThreatId] = useState();

  const [openConsequenceModal, setOpenConsequenceModal] = useState(false);
  const [consequenceId, setConsequenceId] = useState();

  const [openThreatBarrierModal, setOpenThreatBarrierModal] = useState(false);
  const [threatBarrierId, setThreatBarrierId] = useState();

  const [openConsequenceBarrierModal, setOpenConsequenceBarrierModal] =
    useState(false);
  const [consequenceBarrierId, setConsequenceBarrierId] = useState();

  //open the edit bowtie modal
  const editBowtie = (bowtieId) => {
    setBowtieId(bowtieId);
    setOpenBowtieModal(true);
  };

  //open the edit threat modal
  const editThreat = (threatId) => {
    setThreatId(threatId);
    setOpenThreatModal(true);
  };

  //open the edit consequence modal
  const editConsequence = (consequenceId) => {
    setConsequenceId(consequenceId);
    setOpenConsequenceModal(true);
  };

  //open the edit threat barrier modal
  const editThreatBarrier = (threatBarrierId) => {
    setThreatBarrierId(threatBarrierId);
    setOpenThreatBarrierModal(true);
  };

  //open the edit threat barrier modal
  const editConsequenceBarrier = (consequenceBarrierId) => {
    setConsequenceBarrierId(consequenceBarrierId);
    setOpenConsequenceBarrierModal(true);
  };

  // Drawing consts you'll need
  const windowHeight = window.innerHeight - 104;
  const windowWidth = window.innerWidth;
  let longestThreatBarriers = 0;

  //react and redux stuff
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bowtieParam = useParams();
  //const { bowties } = useSelector((state) => state.bowties);
  const bowtie = useSelector((state) =>
    state.bowties.bowties.find((bowtie) => bowtie._id === bowtieParam.bowtie)
  );
  const { threats } = useSelector((state) => state.threats);
  const { barriers } = useSelector((state) => state.barriers);
  const { consequences } = useSelector((state) => state.consequences);
  const { likelihoods } = useSelector((state) => state.likelihood);
  //const { severities } = useSelector((state) => state.severities);

  //you do not need these gets if navigating from the MyFiles dialog, but if you land on bowtie.app/<bowtieid> these will go and grab the data from the db for you.
  //however, this does not work as it should and there are errors appearing in sub components who are expecting this data. The whole page needs to wait for the data to load.
  useEffect(() => {
    //if (bowties.length === 0) {
    if (!bowtie) {
      dispatch(getBowties());
      dispatch(getSeverities());
      dispatch(getLikelihoods());
      dispatch(getThreats(bowtieParam.bowtie));
      dispatch(getBarriers(bowtieParam.bowtie));
      dispatch(getConsequences(bowtieParam.bowtie));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (threats.length === 0 && consequences.length === 0) {
      dispatch(getThreats(bowtieParam.bowtie));
      dispatch(getBarriers(bowtieParam.bowtie));
      dispatch(getConsequences(bowtieParam.bowtie));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updateThreatLikelihoodValues = async () => {
      for (const threat of threats) {
        if(!threat.likelihoodValue && threat.likelihood) {
        const likelihood = likelihoods.find(
          (likelihood) => likelihood._id === threat.likelihood
        );
          if (likelihood && likelihood.number) {
            const updatedThreat = {...threat, likelihoodValue: likelihood.number};
            dispatch(updateThreat(updatedThreat));
        }
      }
    }
    }
    if (threats.length > 0 && likelihoods.length > 0) {
      updateThreatLikelihoodValues();
    }
  }, [threats, likelihoods, dispatch]);

  useEffect(() => {

    if (!bowtieParam) {
      toast("we didn't find a bowtie, so navigating home");
      navigate("/");
    }
}, [bowtieParam, bowtie, navigate]);

  // if(bowties.isLoading) {
  //   return <div>Loading...</div>
  // }

  //Working bowtie information
  //TRYING TO CONNECT THE BOWTIE TO REDUX STORE COMMENTING THIS OUT FOR NOW
  //const bowtie = bowties.find((bt) => bt._id === bowtieParam.bowtie);

  //ROW COUNT

  //counts the threats and consequences to see which is longer
  //always returns an odd number so that the Event sits in centre with well aligned blocks
  const rowCounter = (threats, consequences) => {
    if (threats.length === 0 && consequences.length === 0) {
      return 1;
    } else {
      const biggerRowCount = Math.max(threats.length, consequences.length);
      const rowCount = (biggerRowCount) => {
        if (biggerRowCount % 2 === 0) {
          return biggerRowCount;
        } else {
          return biggerRowCount + 1;
        }
      };
      return rowCount(biggerRowCount);
    }
  };

  //COLUMN COUNT

  const columnCounter = (threats, barriers, consequences) => {
    //if no barriers, just return threat event consequence column count of 3: (threat, event, consequence)
    if (barriers.length === 0) {
      return 3;
    } else {
      //Threats
      const threatBarrierLengths = [0];
      for (let i = 0; i < threats.length; i++) {
        let threatBarriersCount = barriers.filter(
          (barrier) => barrier.threat === threats[i]._id
        );
        threatBarrierLengths.push(threatBarriersCount.length);
      }
      longestThreatBarriers =
        threatBarrierLengths.length === 0
          ? 0
          : Math.max(...threatBarrierLengths);

      //Consequences
      const consequenceBarrierLengths = [0];
      for (let i = 0; i < consequences.length; i++) {
        let consequenceBarriersCount = barriers.filter(
          (barrier) => barrier.consequence === consequences[i]._id
        );
        consequenceBarrierLengths.push(consequenceBarriersCount.length);
      }
      const longestConsequenceBarriers =
        consequenceBarrierLengths === 0
          ? 0
          : Math.max(...consequenceBarrierLengths);

      return 1 + longestThreatBarriers + 1 + longestConsequenceBarriers + 1;
    }
  };

  //Useful variables
  const columnCount = columnCounter(threats, barriers, consequences);
  const rowCount = rowCounter(threats, consequences);
  const blockWidth = Math.min(250, windowWidth / columnCount);
  const blockHeight = Math.min(150, windowHeight / rowCount);
  const threatColumnHeight = threats.length * blockHeight;
  const consequenceColumnHeight = consequences.length * blockHeight;
  const blockPadder = 0.9;
  const centerHeight = windowHeight / 2 + 3;
  const centerWidth = windowWidth / 2 + windowWidth * 0.005;
  const textXoffset = 0.03;
  const titleYoffset = 0.25;
  const descriptionYoffset = 0.5;
  const threatSumYoffset = 0.8;
  const cqYoffset = 0.83;

  //draw threats function
  const drawThreats = () => {

    return (
      <>
        {threats.map((threat, index) => (
          <g
            key={threat._id}
            id={threat._id}
            onClick={function () {
              editThreat(threat._id);
            }}
          >
            <rect
              x={centerWidth - (columnCount * blockWidth) / 2}
              y={centerHeight - threatColumnHeight / 2 + blockHeight * index}
              width={blockWidth * blockPadder}
              height={blockHeight * blockPadder}
              style={{
                fill: "#e0eafb",
                stroke: "rgb(100,149,237)",
                strokeWidth: "4",
              }}
              rx="1"
              ry="1"
            ></rect>
            <text
              x={
                centerWidth -
                (columnCount * blockWidth) / 2 +
                blockWidth * textXoffset
              }
              y={
                centerHeight -
                threatColumnHeight / 2 +
                blockHeight * index +
                blockHeight * titleYoffset
              }
              fill="black"
              fontWeight="bold"
              fontSize={Math.min(blockHeight * 0.21, 12) + "pt"}
            >
              {stringShortener(threat.title, blockWidth)}
            </text>
            <text
              x={
                centerWidth -
                (columnCount * blockWidth) / 2 +
                blockWidth * textXoffset
              }
              y={
                centerHeight -
                threatColumnHeight / 2 +
                blockHeight * index +
                blockHeight * descriptionYoffset
              }
              fill="black"
              fontSize={Math.min(blockHeight * 0.19, 10) + "pt"}
            >
              {stringShortener(threat.description, blockWidth)}
            </text>
            <g>
              <svg
                // viewBox="3.95 4.911 47.672 51.119"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  style={{
                    stroke: "rgb(0, 0, 0)",
                    fill: `rgb(${
                      threat.likelihoodValue
                        ? 306 - 51 * threat.likelihoodValue
                        : 175
                    }, ${
                      threat.likelihoodValue
                        ? 306 - 51 * threat.likelihoodValue
                        : 175
                    }, 255)`,
                  }}
                  cx={
                    centerWidth -
                    (columnCount * blockWidth) / 2 +
                    blockWidth * blockPadder -
                    blockHeight * 0.1
                    // centerWidth -
                    // (columnCount * blockWidth) / 2 +
                    // blockWidth * 0.85
                  }
                  cy={
                    centerHeight -
                    threatColumnHeight / 2 +
                    blockHeight * index +
                    blockHeight * blockPadder -
                    blockHeight * 0.1
                    // centerHeight -
                    // threatColumnHeight / 2 +
                    // blockHeight * index +
                    // blockHeight * severityYoffset
                  }
                  rx={blockHeight * 0.1}
                  ry={blockHeight * 0.1}
                />
                <text
                  style={{
                    fill: `${
                      threat.likelihoodValue
                        ? threat.likelihoodValue > 2
                          ? "rgb(255,255,255)"
                          : "rgb(0,0,0)"
                        : "rgb(0,0,0)"
                    }`,
                    fontSize: `${blockHeight * 0.15}px`,
                    textAnchor: "middle", // Centers text horizontally
                    dominantBaseline: "central", // Centers text vertically
                  }}
                  x={
                    centerWidth -
                    (columnCount * blockWidth) / 2 +
                    blockWidth * blockPadder -
                    blockHeight * 0.1
                  }
                  y={
                    centerHeight -
                    threatColumnHeight / 2 +
                    blockHeight * index +
                    blockHeight * blockPadder -
                    blockHeight * 0.1
                  }
                >
                  {threat.likelihoodValue ? threat.likelihoodValue : "?"}
                </text>
              </svg>
            </g>
          </g>
        ))}
      </>
    );
  };

  //draw consequences function
  const drawConsequences = () => {

    return (
      <>
        {consequences.map((consequence, index) => (
          <g
            key={consequence._id}
            id={consequence._id}
            onClick={function () {
              editConsequence(consequence._id);
            }}
          >
            <rect
              x={centerWidth + (columnCount * blockWidth) / 2 - blockWidth}
              y={centerHeight - consequenceColumnHeight / 2 + blockHeight * index}
              width={blockWidth * blockPadder}
              height={blockHeight * blockPadder}
              style={{
                fill: "#ffdcd6",
                stroke: "RGB(255,78,51)",
                strokeWidth: "4",
              }}
              rx="1"
              ry="1"
            ></rect>
            <text
              x={
                centerWidth +
                (columnCount * blockWidth) / 2 -
                blockWidth * (1 - textXoffset)
              }
              y={
                centerHeight -
                consequenceColumnHeight / 2 +
                blockHeight * index +
                blockHeight * titleYoffset
              }
              fill="black"
              fontWeight="bold"
              fontSize={Math.min(blockHeight * 0.21, 12) + "pt"}
            >
              {stringShortener(consequence.title, blockWidth)}
            </text>
            <text
              x={
                centerWidth +
                (columnCount * blockWidth) / 2 -
                blockWidth * (1 - textXoffset)
              }
              y={
                centerHeight -
                consequenceColumnHeight / 2 +
                blockHeight * index +
                blockHeight * descriptionYoffset
              }
              fill="black"
              fontSize={Math.min(blockHeight * 0.19, 10) + "pt"}
            >
              {stringShortener(consequence.description, blockWidth)}
            </text>
            <g>
                <ellipse
                  style={{
                    stroke: "rgb(0, 0, 0)",
                    fill: `rgb(255, ${
                      consequence.severityValue
                        ? 306 - 51 * consequence.severityValue
                        : 175
                    }, ${
                      consequence.severityValue
                        ? 306 - 51 * consequence.severityValue
                        : 175
                    })`,
                  }}
                  cx={centerWidth + ((columnCount * blockWidth) / 2) - (blockWidth * (1-blockPadder)) - (blockHeight * 0.1)}
                  cy={
                    centerHeight -
                    consequenceColumnHeight / 2 +
                    blockHeight * index +
                    blockHeight -
                    blockHeight * 0.1 * 2
                  }
                  rx={blockHeight * 0.1}
                  ry={blockHeight * 0.1}
                />
                <text
                  style={{
                    fill: `${
                      consequence.severityValue
                        ? consequence.severityValue > 2
                          ? "rgb(255,255,255)"
                          : "rgb(0,0,0)"
                        : "rgb(0,0,0)"
                    }`,
                    //insufficient contrast with the following mehtod:
                    //fill: `rgb(${51*severityNumber(consequence.severity)}, ${51*severityNumber(consequence.severity)}, ${51*severityNumber(consequence.severity)})`,
                    fontSize: `${blockHeight * 0.15}px`,
                    textAnchor: "middle", // Centers text horizontally
                    dominantBaseline: "central", // Centers text vertically
                  }}
                  x={centerWidth + ((columnCount * blockWidth) / 2) - (blockWidth * (1-blockPadder)) - (blockHeight * 0.1)}
                  y={
                    centerHeight -
                    consequenceColumnHeight / 2 +
                    blockHeight * index +
                    blockHeight -
                    blockHeight * 0.1 * 2
                  }
                >
                  {consequence.severityValue ? consequence.severityValue : "?"}
                </text>
            </g>
          </g>
        ))}
      </>
    );
  };

  //draw threat barriers function
  const drawThreatBarriers = () => {
    return (
      <>
        {threats.map((threat, threatIndex) => {
          let barrierIndex = 0;
          // Filter barriers for the current threat
        const filteredBarriers = barriers.filter(
          (barrier) => barrier.threat === threat._id
        );

        // Sort the filtered barriers by position
        const sortedBarriers = filteredBarriers.sort(
          (a, b) => a.position - b.position
        );

          return sortedBarriers.map((barrier) => {
            if (barrier.threat === threat._id) {
              barrierIndex += 1;
              return (
                <g
                  key={barrier._id}
                  id={barrier._id}
                  onClick={function () {
                    editThreatBarrier(barrier._id);
                  }}
                >
                  <rect
                    x={
                      centerWidth -
                      (columnCount * blockWidth) / 2 +
                      blockWidth * barrierIndex
                    }
                    y={
                      centerHeight -
                      threatColumnHeight / 2 +
                      blockHeight * threatIndex
                    }
                    width={blockWidth * blockPadder}
                    height={blockHeight * blockPadder}
                    style={{
                      fill: "#fff7cc",
                      stroke: "RGB(255,215,0)",
                      strokeWidth: "4",
                    }}
                    rx="1"
                    ry="1"
                  ></rect>
                  <text
                    x={
                      centerWidth -
                      (columnCount * blockWidth) / 2 +
                      blockWidth * barrierIndex +
                      blockWidth * textXoffset
                    }
                    y={
                      centerHeight -
                      threatColumnHeight / 2 +
                      blockHeight * threatIndex +
                      blockHeight * titleYoffset
                    }
                    fill="black"
                    fontWeight="bold"
                    fontSize={Math.min(blockHeight * 0.21, 12) + "pt"}
                  >
                    {stringShortener(barrier.title, blockWidth)}
                    {/* {`,  position: ${barrier.position}`} */}
                  </text>
                  <text
                    x={
                      centerWidth -
                      (columnCount * blockWidth) / 2 +
                      blockWidth * barrierIndex +
                      blockWidth * textXoffset
                    }
                    y={
                      centerHeight -
                      threatColumnHeight / 2 +
                      blockHeight * threatIndex +
                      blockHeight * descriptionYoffset
                    }
                    fill="black"
                    fontSize={Math.min(blockHeight * 0.19, 10) + "pt"}
                  >
                    {stringShortener(barrier.description, blockWidth)}
                  </text>
                  <text
                    fontSize="x-small"
                    x={
                      centerWidth -
                      (columnCount * blockWidth) / 2 +
                      blockWidth * barrierIndex +
                      blockWidth * textXoffset
                    }
                    y={
                      centerHeight -
                      threatColumnHeight / 2 +
                      blockHeight * threatIndex +
                      blockHeight * cqYoffset
                    }
                    fill="black"
                  >
                    {barrier.quality
                      ? stringShortener(`Q:${barrier.quality}% `, blockWidth)
                      : ""}
                    {barrier.condition
                      ? stringShortener(`C:${barrier.condition}% `, blockWidth)
                      : ""}
                  </text>
                </g>
              );
            } else {
              return "";
            }
          });
        })}
      </>
    );
  };

  //draw consequence barriers function
  const drawConsequenceBarriers = () => {
    return (
      <>
        {consequences.map((consequence, consequenceIndex) => {
          let barrierIndex = 0;
          return barriers.map((barrier) => {
            if (barrier.consequence === consequence._id) {
              barrierIndex += 1;
              return (
                <g
                  key={barrier._id}
                  id={barrier._id}
                  onClick={function () {
                    editConsequenceBarrier(barrier._id);
                  }}
                >
                  <rect
                    x={
                      centerWidth +
                      (columnCount * blockWidth) / 2 -
                      blockWidth * (1 + barrierIndex)
                    }
                    y={
                      centerHeight -
                      consequenceColumnHeight / 2 +
                      blockHeight * consequenceIndex
                    }
                    width={blockWidth * blockPadder}
                    height={blockHeight * blockPadder}
                    style={{
                      fill: "#fff7cc",
                      stroke: "RGB(255,215,0)",
                      strokeWidth: "4",
                    }}
                    rx="1"
                    ry="1"
                  ></rect>
                  <text
                    x={
                      centerWidth +
                      (columnCount * blockWidth) / 2 -
                      blockWidth * (1 + barrierIndex) +
                      blockWidth * textXoffset
                    }
                    y={
                      centerHeight -
                      consequenceColumnHeight / 2 +
                      blockHeight * consequenceIndex +
                      blockHeight * titleYoffset
                    }
                    fill="black"
                    fontWeight="bold"
                    fontSize={Math.min(blockHeight * 0.21, 12) + "pt"}
                  >
                    {stringShortener(barrier.title, blockWidth)}
                  </text>
                  <text
                    x={
                      centerWidth +
                      (columnCount * blockWidth) / 2 -
                      blockWidth * (1 + barrierIndex) +
                      blockWidth * textXoffset
                    }
                    y={
                      centerHeight -
                      consequenceColumnHeight / 2 +
                      blockHeight * consequenceIndex +
                      blockHeight * descriptionYoffset
                    }
                    fill="black"
                    fontSize={Math.min(blockHeight * 0.19, 10) + "pt"}
                  >
                    {stringShortener(barrier.description, blockWidth)}
                  </text>
                  <text
                    fontSize="x-small"
                    x={
                      centerWidth +
                      (columnCount * blockWidth) / 2 -
                      blockWidth * (1 + barrierIndex) +
                      blockWidth * textXoffset
                    }
                    y={
                      centerHeight -
                      consequenceColumnHeight / 2 +
                      blockHeight * consequenceIndex +
                      blockHeight * cqYoffset
                    }
                    fill="black"
                  >
                    {barrier.quality
                      ? stringShortener(`Q:${barrier.quality}% `, blockWidth)
                      : ""}
                    {barrier.condition
                      ? stringShortener(`C:${barrier.condition}% `, blockWidth)
                      : ""}
                  </text>
                </g>
              );
            } else {
              return "";
            }
          });
        })}
      </>
    );
  };

  //bowtie modal is different from other blocks, as we know one bowtie from url/params
  //and we don't want to load the modal just because of the url. Load it on click.
  const bowtieModal = () => {
    if (bowtieId) {
      return (
        <EditBowtieModal
          openBowtieModal={openBowtieModal}
          setOpenBowtieModal={setOpenBowtieModal}
          bowtieId={bowtieId}
          setBowtieId={setBowtieId}
        />
      );
    }
  };

  const threatModal = () => {
    if (threatId) {
      return (
        <EditThreatModal
          openThreatModal={openThreatModal}
          setOpenThreatModal={setOpenThreatModal}
          threatId={threatId}
          setThreatId={setThreatId}
        />
      );
    }
  };

  const consequenceModal = () => {
    if (consequenceId) {
      return (
        <EditConsequenceModal
          openConsequenceModal={openConsequenceModal}
          setOpenConsequenceModal={setOpenConsequenceModal}
          consequenceId={consequenceId}
          setConsequenceId={setConsequenceId}
        />
      );
    }
  };

  const threatBarrierModal = () => {
    if (threatBarrierId) {
      return (
        <EditThreatBarrierModal
          openThreatBarrierModal={openThreatBarrierModal}
          setOpenThreatBarrierModal={setOpenThreatBarrierModal}
          threatBarrierId={threatBarrierId}
          setThreatBarrierId={setThreatBarrierId}
        />
      );
    }
  };

  const consequenceBarrierModal = () => {
    if (consequenceBarrierId) {
      return (
        <EditConsequenceBarrierModal
          openConsequenceBarrierModal={openConsequenceBarrierModal}
          setOpenConsequenceBarrierModal={setOpenConsequenceBarrierModal}
          consequenceBarrierId={consequenceBarrierId}
          setConsequenceBarrierId={setConsequenceBarrierId}
        />
      );
    }
  };

  return (
    <>
      <svg width={windowWidth} height={windowHeight}>
        {drawThreats()}
        {drawConsequences()}
        {drawThreatBarriers()}
        {drawConsequenceBarriers()}
        <g
          id={bowtieParam.bowtie}
          key={bowtieParam.bowtie}
          onClick={function () {
            editBowtie(bowtie._id);
          }}
        >
          <rect
            x={
              centerWidth -
              (columnCount * blockWidth) / 2 +
              (longestThreatBarriers + 1) * blockWidth
            }
            y={centerHeight - blockHeight / 2}
            width={blockWidth * blockPadder}
            height={blockHeight * blockPadder}
            style={{ fill: "#ffedcc", stroke: "orange", strokeWidth: "4" }}
            rx="10"
            ry="10"
          ></rect>
          <text
            x={
              centerWidth -
              (columnCount * blockWidth) / 2 +
              (longestThreatBarriers + 1) * blockWidth +
              blockWidth * textXoffset
            }
            y={centerHeight - blockHeight / 2 + blockHeight * titleYoffset}
            fill="black"
            fontWeight="bold"
            fontSize={Math.min(blockHeight * 0.21, 12) + "pt"}
          >
            {bowtie
              ? stringShortener(bowtie.title, blockWidth)
              : "awaiting data"}
          </text>
          <text
            x={
              centerWidth -
              (columnCount * blockWidth) / 2 +
              (longestThreatBarriers + 1) * blockWidth +
              blockWidth * textXoffset
            }
            y={
              centerHeight - blockHeight / 2 + blockHeight * descriptionYoffset
            }
            fill="black"
            fontSize={Math.min(blockHeight * 0.19, 10) + "pt"}
          >
            {bowtie
              ? stringShortener(bowtie.event, blockWidth)
              : "awaiting data"}
          </text>
          <text
            x={
              centerWidth -
              (columnCount * blockWidth) / 2 +
              (longestThreatBarriers + 1) * blockWidth +
              blockWidth * textXoffset
            }
            y={
              centerHeight - blockHeight / 2 + blockHeight * threatSumYoffset
            }
            fill="black"
            fontSize="x-small"
            // fontSize={Math.min(blockHeight * 0.19, 10) + "pt"}
          >
            {bowtie && bowtie.threatSum
              ? stringShortener(`Likelihood: ${bowtie.threatSum.toFixed(2)}`, blockWidth)
              : ""}
          </text>
        </g>
      </svg>
      {/* <div style={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', border: '1px solid black' }}>
        <p>columnCount: {columnCount}</p>
        <p>blockWidth: {blockWidth}</p>
        <p>blockHeight: {blockHeight}</p>
      </div> */}
      {threatModal()}
      {consequenceModal()}
      {threatBarrierModal()}
      {consequenceBarrierModal()}
      {bowtieModal()}
    </>
  );
}

export default Canvas;
