import axios from 'axios'

const API_URL = '/api/consequences/'

//create consequence
const createConsequence = async (consequenceData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    const response = await axios.post(API_URL, consequenceData, config)
    return response.data
}

//update consequence
const updateConsequence = async (consequenceData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    const response = await axios.put(API_URL+consequenceData._id, consequenceData, config)
    return response.data

}

//get all consequences for a bowtie
const getConsequences = async (bowtieId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    const response = await axios.get(API_URL+bowtieId, config)
    return response.data
}

//delete consequence
const deleteConsequence = async (consequenceId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    const response = await axios.delete(API_URL+consequenceId, config)
    return response.data
}


const consequenceService = {
    createConsequence,
    getConsequences,
    updateConsequence,
    deleteConsequence
}

export default consequenceService