import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Grid,
  Chip,
  CardActions,
  Tooltip,
} from "@mui/material";
import { useState, useEffect } from "react";
//this filename has changed
import Lines from "../components/ViewerLines";

function ViewerCanvas(props) {
  const total = props.total;
  const [sizes, setSizes] = useState();

  useEffect(() => {
    if (total) {
      const maxThreatBarriers = total.threats.map((threat) => {
        return total.barriers.filter((barrier) => {
          return barrier.threat === threat._id;
        }).length;
      });

      const maxConsequenceBarriers = total.consequences.map((consequence) => {
        return total.barriers.filter((barrier) => {
          return barrier.consequence === consequence._id;
        }).length;
      });

      const preventionLength = 1 + Math.max(...maxThreatBarriers);
      const mitigationLength = 1 + Math.max(...maxConsequenceBarriers);
      const longestSide = Math.max(preventionLength, mitigationLength);
      const totalColumns = 1 + preventionLength + mitigationLength;

      setSizes({
        preventionLength,
        mitigationLength,
        longestSide,
        totalColumns,
      });
    }
  }, [total]);

  //spinner until bowtie data has been fetched
  if (!total) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const blockGenerator = (blocks, blockType) => {
    return (
      <>
        {blocks.map((block) => (
          <Grid
            container
            key={block._id}
            direction={
              blockType === "threat"
                ? "row"
                : blockType === "consequence"
                ? "row-reverse"
                : "row"
            }
            spacing={2}
            mb={2}
            sx={{ flexWrap: "nowrap" }}
            columns={sizes.longestSide}
          >
            <Grid item xs={1}>
              <Card
                id={block._id}
                sx={{
                  backgroundColor:
                    blockType === "threat"
                      ? "#E0EAFB"
                      : blockType === "consequence"
                      ? "#FFDCD6"
                      : "#FFEFD1",
                  "&:hover": {
                    boxShadow: 4,
                  },
                  border:
                    blockType === "threat"
                      ? "3px solid #6495ED"
                      : "3px solid #FF4E33",
                  height: "100%",
                }}
              >
                <CardContent sx={{ p: "5px", "&:last-child": { pb: "5px" } }}>
                  <Typography sx={{ fontWeight: 500 }} align="left">
                    {block.title}
                  </Typography>
                  <Typography align="left" fontSize={13} sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}> 
                    {block.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {total.barriers
              .filter((barrier) => {
                return barrier[blockType] === block._id;
              })
              .map((barrier, i, arr) => (
                <>
                  <Grid item xs={1} key={barrier._id}>
                    <Card
                      id={barrier._id}
                      sx={{
                        backgroundColor: "#FFF7CC",
                        "&:hover": {
                          boxShadow: 4,
                        },
                        border: "3px solid #FFD700",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent
                        sx={{
                          height: "100%",
                          p: "5px",
                          "&:last-child": { pb: "5px" },
                        }}
                      >
                        <Typography sx={{ fontWeight: 500 }} align="left">
                          {barrier.title}
                        </Typography>
                        <Typography
                          align="left"
                          fontSize={12}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {barrier.description}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ mt: "auto" }}>
                        <Tooltip
                          title={`Quality: ${barrier.quality}% Condition: ${barrier.condition}%`}
                        >
                          <Chip
                            color="primary"
                            size="small"
                            label={`Q: ${barrier.quality}% C: ${barrier.condition}%`}
                          />
                        </Tooltip>
                      </CardActions>
                    </Card>
                  </Grid>
                </>
              ))}
          </Grid>
        ))}
      </>
    );
  };

  if (sizes) {
    return (
      <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ width: "100vw", height: "100vh" }}
        p={1}
        columns={sizes.totalColumns}
      >
        <Grid item width="43%">
          {blockGenerator(total.threats, "threat")}
        </Grid>
        <Grid item width="2%"></Grid>
        <Grid item width="10%" justifyContent="center" alignItems="center">
          <Card
            id={total.bowtie._id}
            sx={{
              backgroundColor: "#FFEFD1",
              "&:hover": { boxShadow: 4 },
              border: "3px solid #FFA500",
            }}
          >
            <CardContent>
              <Typography sx={{ fontWeight: 500 }}>
                {total.bowtie.title}
              </Typography>
              {/* <Typography
                align="left"
                fontSize={12}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {total.bowtie.description}
              </Typography> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item width="2%"></Grid>
        <Grid item width="43%">
          {blockGenerator(total.consequences, "consequence")}
        </Grid>
      </Grid>
      <Lines total={total}/>
      </>
    );
  
    

  }
}

export default ViewerCanvas;
