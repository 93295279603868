import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createThreat } from "../features/bowties/threatSlice";
import { useParams } from "react-router-dom";
import BoltIcon from "@mui/icons-material/Bolt";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import axios from "axios";
import { getLikelihoods } from "../features/bowties/likelihoodSlice";

function NewThreatModal(props) {
  const { bowtie } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [aiButton, setAiButton] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const likelihood = useSelector((state) => state.likelihood);
  
  const dispatch = useDispatch();

  //if no likelihoods, get them
  useEffect(() => {
    if (likelihood.likelihoods.length === 0) {
      dispatch(getLikelihoods());
    }
  }, [likelihood.likelihoods.length, dispatch]);

  const initial = {
    title: "",
    description: "",
    bowtie: bowtie,
    likelihood: likelihood.likelihoods.length === 0 ? null : likelihood.likelihoods[0]._id,
    likelihoodValue: likelihood.likelihoods.length === 0 ? null : likelihood.likelihoods[0].number,
  };

  const [newThreat, setNewThreat] = useState(initial);

  useEffect(() => {
    newThreat.description === "" ? setAiButton(true) : setAiButton(false);
  }, [newThreat.description]);

  const handleClose = () => {
    props.setOpenNewThreatModal(false);
    setAiButton(true);
    setNewThreat(initial);
  };

  const handleChangeTitle = (e) => {
    setTitleError(false);
    setNewThreat({
      ...newThreat,
      title: e.target.value,
    });
  };

  const handleChangeDescription = (e) => {
    setNewThreat({
      ...newThreat,
      description: e.target.value,
    });
  };

  const handleChangeLikelihood = (e) => {
    const selectedLikelihood = likelihood.likelihoods.find(likelihood => likelihood._id === e.target.value);
    const likelihoodValue = selectedLikelihood ? selectedLikelihood.number : 1;
    setNewThreat({
      ...newThreat,
      likelihood: e.target.value,
      likelihoodValue: likelihoodValue,
    });
  };

  

  

  const likelihoodSelect = likelihood.likelihoods.map((likelihood) => (
    <MenuItem value={likelihood._id} key={likelihood._id}>
      {likelihood.number}: {likelihood.likelihood}
    </MenuItem>
  ));

  const generateThreatTitle = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setAiButton(true);
    const response = await axios.post(
      "/api/ai/threat",
      { description: newThreat.description },
      config
    );
    setAiButton(false);
    //fake an onChange event from the DOM to piggy back the handler, so as to avoid invoking a controlled input by setting its value
    const newObj = { target: { value: response.data } };
    handleChangeTitle(newObj);
  };

  const handleSave = () => {
    //clean up
    setTitleError(false);

    if (newThreat.title === "") {
      setTitleError(true);
    } else {
      dispatch(createThreat(newThreat));
      handleClose();
    }
  };

  return (
    <Dialog open={props.openNewThreatModal} onClose={handleClose} fullWidth>
      <DialogTitle>
        <BoltIcon
          sx={{ color: "#6495ED", position: "absolute", top: 20, right: 20 }}
        />
        Create a new Threat
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={10}>
            <TextField
              autoFocus
              margin="dense"
              label="Title"
              name="title"
              required
              error={titleError}
              fullWidth
              variant="outlined"
              onChange={handleChangeTitle}
              placeholder={"Please provide a title for this threat"}
              value={newThreat.title}
              helperText={titleError ? "required" : ""}
            />
          </Grid>
          <Grid
            item
            xs={2}
            p={1}
            alignItems="stretch"
            style={{ display: "flex" }}
          >
            <Tooltip
              title={
                !aiButton
                  ? "use AI 🤖 to convert your description into a concise summarised title"
                  : ""
              }
            >
              <Button
                variant="contained"
                name="generate ai summary title"
                color="secondary"
                onClick={generateThreatTitle}
                disabled={aiButton}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  minWidth: "100%",
                  minHeight: "100%",
                }}
              >
                <PsychologyOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              multiline
              rows={4}
              name="description"
              variant="outlined"
              onChange={handleChangeDescription}
              placeholder={"Please provide a description of this threat"}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <FormControl fullWidth>
              <InputLabel id="likelihood-label">Likelihood</InputLabel>
              <Select
                labelId="likelihood-select-label"
                id="likelihood-select"
                value={newThreat.likelihood}
                label="Likelihood"
                onChange={handleChangeLikelihood}
              >
                {likelihoodSelect}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewThreatModal;
