import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { createBarrier } from "../features/bowties/barrierSlice";
import { useParams } from "react-router-dom";
import HealingIcon from "@mui/icons-material/Healing";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { Tooltip, Grid, FormHelperText } from "@mui/material";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";

function NewConsequenceBarrierModal(props) {
  const { bowtie } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [aiButton, setAiButton] = useState(true);
  const [titleError, setTitleError] = useState(false)
  const [consequenceError, setConsequenceError] = useState(false)

  const initial = {
    title: "",
    description: "",
    consequence: "",
    bowtie: bowtie,
    condition: "",
    quality: ""
  };

  const [newConsequenceBarrier, setNewConsequenceBarrier] = useState(initial);

  useEffect(() => {
    newConsequenceBarrier.description === ""
      ? setAiButton(true)
      : setAiButton(false);
  }, [newConsequenceBarrier.description]);

  const handleClose = () => {
    props.setOpenNewConsequenceBarrierModal(false);
    setAiButton(true);
    setNewConsequenceBarrier(initial);
  };

  const consequences = useSelector((state) =>
    state.consequences.consequences.filter(
      (consequence) => consequence.bowtie === bowtie
    )
  );

  const consequenceSelect = consequences.map((consequence) => (
    <MenuItem value={consequence._id} key={consequence._id}>
      {consequence.title}
    </MenuItem>
  ));

  const handleChangeTitle = (e) => {
    setTitleError(false)
    setNewConsequenceBarrier({
      ...newConsequenceBarrier,
      title: e.target.value,
    });
  };

  const handleChangeDescription = (e) => {
    setNewConsequenceBarrier({
      ...newConsequenceBarrier,
      description: e.target.value,
    });
  };

  const handleChangeConsequence = (e) => {
    setConsequenceError(false)
    setNewConsequenceBarrier({
      ...newConsequenceBarrier,
      consequence: e.target.value,
    });
  };

  const min = 0;
  const max = 100;

  const handleChangeCondition = (e) => {
    const conditionPercentage = Math.max(min, Math.min(max, Number(e.target.value)));
    setNewConsequenceBarrier({
      ...newConsequenceBarrier,
      condition: conditionPercentage,
    });
  };

  const handleChangeQuality = (e) => {
    const qualityPercentage = Math.max(min, Math.min(max, Number(e.target.value)));
    setNewConsequenceBarrier({
      ...newConsequenceBarrier,
      quality: qualityPercentage,
    });
  };

  const generateConsequenceBarrierTitle = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setAiButton(true);
    const response = await axios.post(
      "/api/ai/consequence-barrier",
      { description: newConsequenceBarrier.description },
      config
    );
    setAiButton(false);
    //fake an onChange event from the DOM to piggy back the handler, so as to avoid invoking a controlled input by setting its value
    const newObj = { target: { value: response.data } };
    handleChangeTitle(newObj);
  };

  const dispatch = useDispatch();

  const handleSave = () => {
    //clean up
    setTitleError(false)
    setConsequenceError(false)

    if(newConsequenceBarrier.title === "") {
      setTitleError(true)
    } else if (newConsequenceBarrier.consequence === ""){
      setConsequenceError(true)
    } else {
    dispatch(createBarrier(newConsequenceBarrier));
    handleClose();
    }
  };

  return (
    
      <Dialog
        open={props.openNewConsequenceBarrierModal}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>
          <HealingIcon
            sx={{ color: "#FFD700", position: "absolute", top: 20, right: 20 }}
          />
          Create a new Mitigative Barrier
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <TextField
                autoFocus
                margin="dense"
                label="Title"
                name="title"
                fullWidth
                required
                error={titleError}
                helperText={titleError?"required":""}
                variant="outlined"
                onChange={handleChangeTitle}
                placeholder={"Please provide a title for this barrier"}
                value={newConsequenceBarrier.title}
              />
            </Grid>
            <Grid
              item
              xs={2}
              alignItems="stretch"
              style={{ display: "flex" }}
            >
              <Tooltip
                title={
                  !aiButton
                    ? "use AI 🤖 to convert your description into a concise summarised title"
                    : ""
                }
              >
                <Button
                  variant="contained"
                  name="generate ai summary title"
                  color="secondary"
                  onClick={generateConsequenceBarrierTitle}
                  disabled={aiButton}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    minWidth: "100%",
                    minHeight: "100%",
                  }}
                >
                  <PsychologyOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Description"
                fullWidth
                multiline
                rows={4}
                name="description"
                variant="outlined"
                onChange={handleChangeDescription}
                placeholder={"Please provide a description of this barrier"}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <FormControl fullWidth required>
                <InputLabel error={consequenceError} id="consequence-label">
                  Consequence
                </InputLabel>
                <Select
                  labelId="consequence-select-label"
                  id="consequence-select"
                  value={newConsequenceBarrier.consequence}
                  label="Consequence"
                  onChange={handleChangeConsequence}
                  required
                  error={consequenceError}
                >
                  {consequenceSelect}
                </Select>
                {consequenceError?<FormHelperText error>required</FormHelperText>:""}
              </FormControl>
          </Grid>
          <Grid item xs={6} mt={1.5}>
            <TextField
              label="Control Quality"
              id="control-quality"
              type="number"
              fullWidth
              name="quality"
              placeholder={"How reliable is this barrier?"}
              onChange={handleChangeQuality}
              value={newConsequenceBarrier.quality}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6} mt={1.5}>
            <TextField
              label="Control Condition"
              id="control-condition"
              type="number"
              fullWidth
              name="condition"
              placeholder={"Barrier condition right now?"}
              onChange={handleChangeCondition}
              value={newConsequenceBarrier.condition}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    
  );
}

export default NewConsequenceBarrierModal;
