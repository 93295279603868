import * as React from "react";
import ViewerCanvas from "../components/ViewerCanvas";
import { useXarrow } from "react-xarrows";
import {
  TransformWrapper,
  TransformComponent
} from "react-zoom-pan-pinch";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";

function Viewer() {
  const id = useParams();
  const [total, setTotal] = React.useState();

  const updateXarrow = useXarrow();


  React.useEffect(() => {
    const fetchBowtie = async () => {
      const {
        bowtie,
        likelihoods,
        severities,
        threats,
        consequences,
        barriers,
      } = await fetch(`/api/viewer/${id.bowtie}`).then((r) => r.json());

      setTotal({
        bowtie,
        likelihoods,
        severities,
        threats,
        consequences,
        barriers,
      });
    };
    fetchBowtie();
    // eslint-disable-next-line
  }, []);

  if (total) {
    return (
      <>
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          onPanningStop={updateXarrow}
          // onWheelStop={updateXarrow}
          onZoomStop={updateXarrow}
          // limitToBounds={true}
        >
          {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
            <React.Fragment>
              <div className="zoombox">
                <Stack spacing={1} direction="row">
                  <Tooltip title="zoom in">
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        zoomIn();
                      }}
                    >
                      <ZoomInIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="zoom out">
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        zoomOut();
                        updateXarrow();
                      }}
                    >
                      <ZoomOutIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="center">
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        centerView();
                        updateXarrow();
                      }}
                    >
                      <CenterFocusStrongIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="zoom extents">
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        resetTransform();
                        updateXarrow();
                      }}
                    >
                      <ZoomOutMapIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </div>
              <TransformComponent>
                <ViewerCanvas total={total} />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>

        {/* <Lines total={total}/> */}
      </>
    );
  }
}

export default Viewer;
